import { Franchise } from "./franchise.model";

export class Room {
    id?: number;
    franchise?: Franchise;
    title?: string;
    description?: string;
    seats?: number;
    floor?: number;
    status?: ('active'|'inactive');
    created?: string;

    static fromJson(fields: any): Room {
        let item = Object.assign(new this(), fields);
        item.franchise = Franchise.fromJson(fields?.franchise);

        return item;
    }
};
