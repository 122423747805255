<div class="card" id="basicInfo" *ngIf="partner?.id">
    <div class="card-body">
        <div class="row">
            <div class="col-auto">
                <div class="m-3">
                    <avatar type="drop" size="big" class="d-none d-lg-inline-block" [photo]="partner?.icon ?? partner?.logo ?? ''"></avatar>
                    <avatar type="drop" size="small" class="d-lg-none" [photo]="partner?.icon ?? partner?.logo ?? ''"></avatar>
                </div>
            </div>
            <div class="col">
                <span *ngIf="(user | roleAny:'admin')">
                    <a routerLink="/messages" [queryParams]="{conversation: partner?.admin?.id, tab: 'conversation'}" class="btn btn-primary btn-sm float-end">
                        <i class="fa-regular fa-envelope fa-fw"></i>
                        <span class="ms-1 d-none d-md-inline-block">{{ 'Напиши съобщение' | transloco }}</span>
                    </a>
                </span>

                <h2 class="mb-0">
                    <small class="badge rounded-pill text-bg-danger small me-1" *ngIf="partner?.status === 'inactive'">{{ 'Неактивен' | transloco }}</small>
                    {{ partner?.name ?? '' }}
                </h2>

                <div *ngIf="partner?.primary" class="badge rounded-pill text-bg-warning">{{ 'Основен' | transloco }}</div>

                <div class="row">
                    <div class="col-12 col-md mt-3">
                        <dl class="d-flex col-12 flex-column flex-sm-row m-0">
                            <div class="col-auto col-sm-6 px-2">
                                <dt>{{ 'E-mail' | transloco }}</dt>
                                <dd class="text-nowrap overflow-hidden text-truncate max-width-280">
                                    <i class="fa-solid fa-at fa-fw me-2"></i>
                                    <a *ngIf="partner?.email?.length" [href]="'mailto:' + partner?.email" target="_blank" (click)="$event.stopPropagation()">{{ partner?.email ?? '-' }}</a>
                                    <span *ngIf="!partner?.email?.length">{{ 'Няма' | transloco }}</span>
                                </dd>
                            </div>
                            <div class="col-auto col-sm-6 px-2">
                                <dt>{{ 'Уеб страница' | transloco }}</dt>
                                <dd class="text-nowrap overflow-hidden text-truncate max-width-280">
                                    <i class="fa-solid fa-globe fa-fw me-2"></i>
                                    <a *ngIf="partner?.homepage?.length" [href]="partner?.homepage" target="_blank" (click)="$event.stopPropagation()">{{ partner?.homepage ?? '-' }}</a>
                                    <span *ngIf="!partner?.homepage?.length">{{ 'Няма' | transloco }}</span>
                                </dd>
                            </div>
                        </dl>
                        <dl class="d-flex col-12 flex-column flex-sm-row m-0">
                            <div class="col-auto col-sm-6 px-2">
                                <dt>{{ 'Телефон' | transloco }}</dt>
                                <dd class="text-nowrap overflow-hidden text-truncate max-width-280">
                                    <i class="fa-solid fa-phone fa-fw me-2"></i>
                                    <a *ngIf="partner?.phone?.length" [href]="'tel:' + partner?.phone" target="_blank" (click)="$event.stopPropagation()">{{ partner?.phone ?? '-' }}</a>
                                    <span *ngIf="!partner?.phone?.length">{{ 'Няма' | transloco }}</span>
                                </dd>
                            </div>
                            <div class="col-auto col-sm-6 px-2">
                                <dt>{{ 'Портал' | transloco }}</dt>
                                <dd class="text-nowrap overflow-hidden text-truncate max-width-280">
                                    <i class="fa-solid fa-link fa-fw me-2"></i>
                                    <a *ngIf="partner?.url?.length" [href]="portalPageUrl" target="_blank" (click)="$event.stopPropagation()">{{ partner?.url ?? '-' }}</a>
                                    <span *ngIf="!partner?.url?.length">{{ 'Няма' | transloco }}</span>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="courses" class="my-2" infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScrollDown($event)">

    <div class="col-12 mb-2 mt-5" *ngIf="!loading || partner?.id">
        <div class="input-group search-field-bar form-control">
            <input type="text" class="form-control"
                    [placeholder]="'Търсене' | transloco"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    [(ngModel)]="search"
                    (keyup.enter)="onSearchEnter($event)"
                    (input)="onSearchDebounce($event)"
            />

            <button class="btn btn-outline-danger" type="button" *ngIf="search?.length" (click)="onClearSearch($event)"><i class="fa-solid fa-fw fa-xmark"></i></button>
            <button class="btn btn-outline-secondary submit" type="button" (click)="onSearchEnter($event)"><i class="fa-solid fa-fw fa-magnifying-glass"></i></button>
        </div>
    </div>

    <div class="row mx-0 my-3 align-items-stretch" *ngIf="courses?.data?.length">
        <div class="col-lg-6 mb-2 mb-md-3 p-0 padding-col" *ngFor="let course of courses?.data">

            <card-course    [course]="course"
                            pageUrl="/portal/courses/"
                            [mode]="'view'"
                            (onRemove)="onRemove($event)"
                            [short]="true"
                            [partner]="partner ?? null"
            ></card-course>
        </div>
    </div>
    <div *ngIf="partner?.id && !courses?.data?.length && !this.loading" class="alert alert-warning mt-4">
        {{ 'Няма курсове.' | transloco }}
    </div>
</div>


<modal-confirm *ngIf="showRemoveCourseConfirm"
               [body]="'Желаете ли да премахнете този курс?' | transloco"
               [headline]="'Премахване на курс?' | transloco"
               [skippable]="true"
               (onAction)="onRemoveCourseConfirmed($event)"
></modal-confirm>

