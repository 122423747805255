<p-dialog
    [visible]="true"
    (visibleChange)="onCloseAction($event)"
    [closeOnEscape]="skippable"
    [dismissableMask]="skippable"
    [closable]="closable"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '30vw', 'width': '767px', 'max-width': '90vw'}"
    [class.round-top]="!((headline && headline?.length) || closable) ? true : false"
    [showHeader]="(headline && headline?.length) || closable ? true : false"
    [header]="headline && headline?.length ? headline : '&nbsp;'"
    (onShow)="onShowDialog($event)"
    (onHide)="onHideDialog($event)"
>
    <span [innerHTML]="body" *ngIf="body"></span>

    <p-calendar [(ngModel)]="date"
                [inputStyleClass]="'form-control w-100'"
                hourFormat="24"
                dateFormat="dd.mm.yy"
                [firstDayOfWeek]="1"
                [showIcon]="true"
                [showWeek]="true"
                [inline]="true"
                [showTime]="true"
                [selectOtherMonths]="true"
                [showButtonBar]="false"
    ></p-calendar>

    <ng-template pTemplate="footer">
        <div class="row align-items-center">
            <div class="col-auto text-start">
                <button *ngFor="let item of calendarActions; let index = index"
                        type="button"
                        [title]="item?.tooltip || ''"
                        class="btn {{ item.btnClass || '' }} my-1"
                        [disabled]="loading"
                        (click)="onButtonClicked($event, item.action)"
                >
                    <i class="{{ item.icon }}" *ngIf="item?.icon?.length"></i>
                    {{ item.name || '' }}
                </button>
            </div>

            <div class="col text-end text-nowrap">
                <button *ngFor="let item of actions; let index = index"
                        type="button"
                        [title]="item?.tooltip || ''"
                        class="btn {{ item.btnClass || '' }} my-1"
                        [disabled]="loading"
                        (click)="onButtonClicked($event, item.action)"
                >
                    <i class="{{ item.icon }}" *ngIf="item?.icon?.length"></i>
                    {{ item.name || '' }}
                </button>
            </div>
        </div>
    </ng-template>
</p-dialog>
