export type FranchisePreferenceType = ('integer'|'string'|'boolean');
export type FranchisePreferenceVisibility = ('public'|'private');

export class FranchisePreference {
    static readonly PREFERENCE_CATEGORY_PAYMENTS =                              'payments.';
    static readonly PREFERENCE_CATEGORY_CERTIFICATES =                          'certificate.';

    // Office
    static readonly PREFERENCE_PAYMENTS_OFFICE_STATUS =                         'payments.office.status';
    static readonly PREFERENCE_PAYMENTS_OFFICE_DESCRIPTION =                    'payments.office.description';

    // PayPal
    static readonly PREFERENCE_PAYMENTS_PAYPAL_STATUS =                         'payments.online.paypal.status';
    static readonly PREFERENCE_PAYMENTS_PAYPAL_MODE =                           'payments.online.paypal.mode';

    // PayPal Sandbox
    static readonly PREFERENCE_PAYMENTS_PAYPAL_SANDBOX_CLIENT_ID =              'payments.online.paypal.sandbox.client_id';
    static readonly PREFERENCE_PAYMENTS_PAYPAL_SANDBOX_CLIENT_SECRET =          'payments.online.paypal.sandbox.secret';

    // PayPal Live
    static readonly PREFERENCE_PAYMENTS_PAYPAL_LIVE_CLIENT_ID =                 'payments.online.paypal.live.client_id';
    static readonly PREFERENCE_PAYMENTS_PAYPAL_LIVE_CLIENT_SECRET =             'payments.online.paypal.live.secret';

    // MyPOS
    static readonly PREFERENCE_PAYMENTS_MYPOS_STATUS =                          'payments.online.mypos.status';
    static readonly PREFERENCE_PAYMENTS_MYPOS_TYPE =                            'payments.online.mypos.type';

    // MyPOS Sandbox
    static readonly PREFERENCE_PAYMENTS_MYPOS_SANDBOX_SID =                     'payments.online.mypos.sandbox.sid';
    static readonly PREFERENCE_PAYMENTS_MYPOS_SANDBOX_WALLET =                  'payments.online.mypos.sandbox.wallet';
    static readonly PREFERENCE_PAYMENTS_MYPOS_SANDBOX_KEY_INDEX =               'payments.online.mypos.sandbox.key_index';
    static readonly PREFERENCE_PAYMENTS_MYPOS_SANDBOX_KEY =                     'payments.online.mypos.sandbox.key';
    static readonly PREFERENCE_PAYMENTS_MYPOS_SANDBOX_CERTIFICATE =             'payments.online.mypos.sandbox.certificate';

    // MyPOS Live
    static readonly PREFERENCE_PAYMENTS_MYPOS_LIVE_SID =                        'payments.online.mypos.live.sid';
    static readonly PREFERENCE_PAYMENTS_MYPOS_LIVE_WALLET =                     'payments.online.mypos.live.wallet_number';
    static readonly PREFERENCE_PAYMENTS_MYPOS_LIVE_KEY_INDEX =                  'payments.online.mypos.live.key_index';
    static readonly PREFERENCE_PAYMENTS_MYPOS_LIVE_KEY =                        'payments.online.mypos.live.key';
    static readonly PREFERENCE_PAYMENTS_MYPOS_LIVE_CERTIFICATE =                'payments.online.mypos.live.certificate';

    // Bank Transfer
    static readonly PREFERENCE_PAYMENTS_BANK_STATUS =                           'payments.bank.status';
    static readonly PREFERENCE_PAYMENTS_BANK_DESCRIPTION =                      'payments.bank.description';

    // Customization preferences
    static readonly PREFERENCE_LANDING_FOOTER_INFO =                            'landing.footer.info';

    // Certificates
    static readonly PREFERENCE_CERTIFICATE_COURSE_TITLE =                       'certificate.course.title';
    static readonly PREFERENCE_CERTIFICATE_TEMPLATE_MULTIPAGE =                 'certificate.template.multipage';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_TEMPLATE =                'certificate.first_page.template';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_STUDENT_COORDS_X =        'certificate.first_page.student.coords.x';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_STUDENT_COORDS_Y =        'certificate.first_page.student.coords.y';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_STUDENT_WIDTH =           'certificate.first_page.student.width';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_STUDENT_HEIGHT =          'certificate.first_page.student.height';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_STUDENT_FONT =            'certificate.first_page.student.font';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_STUDENT_SIZE =            'certificate.first_page.student.size';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_DATE_COORDS_X =           'certificate.first_page.date.coords.x';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_DATE_COORDS_Y =           'certificate.first_page.date.coords.y';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_DATE_WIDTH =              'certificate.first_page.date.width';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_DATE_HEIGHT =             'certificate.first_page.date.height';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_DATE_FONT =               'certificate.first_page.date.font';
    static readonly PREFERENCE_CERTIFICATE_FIRST_PAGE_DATE_SIZE =               'certificate.first_page.date.size';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_TEMPLATE =               'certificate.second_page.template';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_COURSE_COORDS_X =        'certificate.second_page.course.coords.x';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_COURSE_COORDS_Y =        'certificate.second_page.course.coords.y';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_COURSE_WIDTH =           'certificate.second_page.course.width';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_COURSE_HEIGHT =          'certificate.second_page.course.height';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_COURSE_FONT =            'certificate.second_page.course.font';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_COURSE_SIZE =            'certificate.second_page.course.size';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_CERT_COORDS_X =          'certificate.second_page.cert.coords.x';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_CERT_COORDS_Y =          'certificate.second_page.cert.coords.y';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_CERT_WIDTH =             'certificate.second_page.cert.width';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_CERT_HEIGHT =            'certificate.second_page.cert.height';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_CERT_FONT =              'certificate.second_page.cert.font';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_CERT_SIZE =              'certificate.second_page.cert.size';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_HELD_DATE_COORDS_X =     'certificate.second_page.held_date.coords.x';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_HELD_DATE_COORDS_Y =     'certificate.second_page.held_date.coords.y';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_HELD_DATE_WIDTH =        'certificate.second_page.held_date.width';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_HELD_DATE_HEIGHT =       'certificate.second_page.held_date.height';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_HELD_DATE_FONT =         'certificate.second_page.held_date.font';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_HELD_DATE_SIZE =         'certificate.second_page.held_date.size';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_STUDENT_COORDS_X =       'certificate.second_page.student.coords.x';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_STUDENT_COORDS_Y =       'certificate.second_page.student.coords.y';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_STUDENT_WIDTH =          'certificate.second_page.student.width';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_STUDENT_HEIGHT =         'certificate.second_page.student.height';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_STUDENT_FONT =           'certificate.second_page.student.font';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_STUDENT_SIZE =           'certificate.second_page.student.size';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_TOPICS_COORDS_X =        'certificate.second_page.topics.coords.x';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_TOPICS_COORDS_Y =        'certificate.second_page.topics.coords.y';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_TOPICS_WIDTH =           'certificate.second_page.topics.width';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_TOPICS_HEIGHT =          'certificate.second_page.topics.height';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_TOPICS_FONT =            'certificate.second_page.topics.font';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_TOPICS_SIZE =            'certificate.second_page.topics.size';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_DATE_COORDS_X =          'certificate.second_page.date.coords.x';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_DATE_COORDS_Y =          'certificate.second_page.date.coords.y';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_DATE_WIDTH =             'certificate.second_page.date.width';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_DATE_HEIGHT =            'certificate.second_page.date.height';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_DATE_SIZE =              'certificate.second_page.date.size';
    static readonly PREFERENCE_CERTIFICATE_SECOND_PAGE_DATE_FONT =              'certificate.second_page.date.font';

    static readonly PREFERENCE_THEME_COLORS =                                   'theme.colors';

    // Vocational Training Center
    static readonly PREFERENCE_COMPANY_VTC_NAME =                               'company.vtc.name';

    id?: number;
    type?: FranchisePreferenceType;
    visibility?: FranchisePreferenceVisibility;
    name?: string;
    value?: boolean|string|number|null;

    static fromJson(fields: any): FranchisePreference {
        let item = Object.assign(new this(), fields);

        item.value = item?.type === 'integer' ? Number(item.value)
            : item?.type === 'boolean' ? String(item.value).trim().toLowerCase() === 'true'
            : item?.type === 'string' ? String(item.value)
            : null;

        return item;
    }
}
