<div class="teacher-card" [class.noShadow]="innerDialogContent">
    <div class="teacher-list flex-wrap d-flex align-items-start justify-content-start pt-4 py-3">
        <div *ngFor="let teacher of teachers; let index = index" class="col-sm-2 col-4 mx-sm-3 mx-1" [class.mb-4]="!innerDialogContent">

            <avatar [user]="teacher"
                    type="drop"
                    [size]="null"
                    [highlight]="true"
                    [selected]="innerDialogContent && isSelectedTeacher(teacher)"
                    (click)="innerDialogContent ? toggleTeacher(teacher) : showTeacherInfoModal(teacher)"
            ></avatar>

            <div *ngIf="innerDialogContent" class="names-wrapper mt-2 mb-4">
                <p class="names text-wrap text-center">{{ teacher?.name }}  {{ teacher?.lastname }}</p>
            </div>

            <div class="message-wrapper" *ngIf="teacher?.show">
                <ng-container [ngTemplateOutlet]="teacherTemplate" [ngTemplateOutletContext]="{ teacher: teacher }"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #teacherTemplate let-teacher="teacher">
    <div>
        <p-dialog
            [visible]="true"
            [draggable]="false"
            [modal]="true"
            [style]="{'min-width': '40vw', 'width': '991px', 'max-width': '90vw'}"
            [closeOnEscape]="true"
            styleClass="mx-3 mx-sm-0"
            (visibleChange)="showTeacherInfoModal(teacher)"
        >
            <div class="message-bubble row">
                <div class="avatar col-3 d-none d-md-block">
                    <div class="avatar-wrapper m-auto mb-3">
                        <avatar [user]="teacher"
                                type="drop"
                                [size]="null"
                                [highlight]="true"
                        ></avatar>
                    </div>
                </div>
                <div class="col-12 col-md-9">
                    <div class="message-cont mx-sm-3 mx-0">
                        <div class="d-flex">
                            <div class="col-3 d-none d-sm-flex d-md-none">
                                <avatar [user]="teacher"
                                        type="drop"
                                        [size]="null"
                                        [highlight]="true"
                                ></avatar>
                            </div>
                            <div class="col-12 col-sm-9 col-md-12 mx-sm-4 mx-0 mx-md-0">
                                <div class="float-end">
                                    <a *ngIf="user | roleAny:'admin':'partner'" [routerLink]="'/teachers/' + teacher?.id" class="btn btn-primary btn-sm mx-2 mb-2">
                                        <i class="fa-fw fa-person-chalkboard fa-solid"></i>
                                        <span class="d-none d-sm-inline-block ms-1">{{ 'Профил' | transloco }}</span>
                                    </a>

                                    <a *ngIf="teacher?.id !== user?.id"
                                        routerLink="/messages" [queryParams]="{conversation: teacher?.id, tab: 'conversations'}" class="btn btn-primary btn-sm mb-2"
                                    >
                                        <i class="fa-regular fa-envelope fa-fw"></i>
                                        <span class="d-none d-sm-inline-block ms-1">{{ 'Напиши съобщение' | transloco }}</span>
                                    </a>
                                </div>

                                <h3>
                                    {{ teacher.full_name }}
                                    <div class="separator secondary d-flex align-items-center ">
                                        <span></span>
                                        <i class="fa-solid fa-ellipsis mx-2"></i>
                                    </div>
                                </h3>
                                <h4 class="mb-2">{{teacher?.info?.position}}</h4>
                                <a [href]="'mailto:' + teacher.email" target="_blank" class="d-flex"><i class="icon-email me-2"></i> <span>{{ teacher.email }}</span></a>
                            </div>
                        </div>
                        <div class="message-inner-cont my-2">
                            {{teacher?.info?.description}}
                        </div>

                    </div>
                </div>
            </div>
        </p-dialog>
    </div>
</ng-template>
