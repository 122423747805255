import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ApiValidation } from 'src/app/shared/services/api-validation.service';
import { translate } from '@ngneat/transloco';
import { Course } from 'src/app/shared/models/course.model';
import { CourseService } from 'src/app/shared/services/course.service';
import { Module } from 'src/app/shared/models/module.model';

@Component({
    selector: 'modal-course-module-form',
    templateUrl: './modal-course-module-form.component.html',
    styleUrls: ['./modal-course-module-form.component.scss']
})
export class ModalCourseModuleFormComponent implements OnInit {
    protected subscriptions: Subscription[] = [];
    protected innerLoadingQueue: number = 0;
    protected innerModule: Module|null = null;

    form: UntypedFormGroup|null = null;
    errors: any = {};
    loading: boolean = false;
    loadingQueue: number = 0;


    @Output() onAction: EventEmitter<('add'|'close')> = new EventEmitter();

    @Input() course?: Course;

    @Input() mode: ('add'|'edit') = 'add';
    @Input()
    set module(val: Module|null) {
        if (this.innerModule !== val) {
            this.innerModule = val;

            if (this.form) {
                this.form.get('title')?.setValue(this.innerModule?.title?.trim());
                this.form.get('description')?.setValue(this.innerModule?.description?.trim());
                this.form.get('number')?.setValue(this.innerModule?.number?.trim());
                this.form.get('status')?.setValue(this.innerModule?.status === 'active');
            }
        }
    }
    get module(): Module|null {
        return this.innerModule;
    }

    constructor(
        private apiValidation: ApiValidation,
        private courseService: CourseService,
        private messageService: MessageService,
    ) { }

    onCloseAction(event: any): void {
        this.form?.reset();
        this.errors = {};
        this.onAction.emit('close');
    }

    ngOnInit(): void {
        this.initForm();
    }

    protected initForm(): void {
        this.form = new UntypedFormGroup({
            title: new UntypedFormControl(this.innerModule?.title?.trim() ?? '', [
                Validators.required,
                Validators.maxLength(150),
            ]),
            number: new UntypedFormControl(this.innerModule?.number?.trim() ?? '', [
                Validators.required,
                Validators.maxLength(10),
            ]),
            status: new UntypedFormControl(this.innerModule?.status === 'active', [Validators.required]),
            description: new UntypedFormControl(this.innerModule?.description?.trim() ?? '', [
                Validators.maxLength(65535),
            ]),
        });
    }

    onSubmit(event?: Event): void {
        if (this.loading) {
            return;
        }

        if (this.form?.invalid) {
            this.errors = this.form?.controls;
            this.messageService.add({
                severity: 'error',
                summary: translate('Грешка!'),
                detail: translate('Моля, проверете полетата отново!')
            });

            this.form.markAllAsTouched();
            return;
        }

        const formData = this.form?.getRawValue();
        formData.status = formData.status ? 'active' : 'inactive';

        const action = this.mode === 'add'
            ? this.courseService.addModule(this.course?.id ?? 0, formData)
            : this.courseService.updateModule(this.course?.id ?? 0, this.module?.id ?? 0, formData);

        this.loadingQueue++;
        const subscription = action.subscribe({
            next: data => {
                this.loadingQueue--;

                this.messageService.add({
                    severity: 'success',
                    detail: translate('Успешно добавен модул.'),
                });

                this.onAction.emit('add');
                this.onCloseAction(event);
            },
            error: (error: any) => {
                this.loadingQueue--;
                this.apiValidation.checkBadRequest(error, this.form, true).then((data: any) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка!'),
                        detail: data.message
                    });
                    this.errors = data.errors;
                });
            }
        });
        this.subscriptions.push(subscription);
    }
}
