<a [routerLink]="baseViewUrl" *ngIf="pageUrl?.length && mode === 'view'">
    <ng-container [ngTemplateOutlet]="item"></ng-container>
</a>

<div *ngIf="!pageUrl?.length || mode === 'edit'" class="h-100">
    <ng-container [ngTemplateOutlet]="item"></ng-container>
</div>

<ng-template #item>
    <div class="course-card d-flex flex-column flex-lg-row my-3" [class.card-link]="pageUrl?.length" *ngIf="!short">
        <div class="col-lg-6 col-12">
            <div class="course-icon">
                <img [src]="course?.image" [alt]="course?.title || ('Курс' | transloco)" [class.inactive]="course?.status === 'inactive'">
            </div>
        </div>
        <div class="col-lg-6 col-12 p-lg-3">
            <div class="course-cont d-flex flex-column align-items-start justify-content-center">
                <h3 class="mb-3">
                    <small class="badge rounded-pill text-bg-danger small me-1" *ngIf="course?.status === 'inactive'">{{ 'Неактивен' | transloco }}</small>
                    {{ course?.title }}
                </h3>
                <h4 *ngIf="course?.design?.name?.length">{{ 'Категория:' | transloco }} {{ course?.design?.name }}</h4>

                <div class="row text-muted mb-3">
                    <div class="col-auto my-1">
                        <i class="fa-solid fa-tag fa-fw text-dark"></i>
                        {{ course?.price | price }}
                    </div>
                    <div class="col-auto my-1" *ngIf="course?.parsed_starting">
                        <i class="fa-solid fa-calendar-check fa-fw text-dark"></i>
                        {{ course?.parsed_starting|dateLocale:"d MMMM y" }}
                    </div>
                    <div class="col-auto my-1" *ngIf="course?.duration">
                        <i class="fa-solid fa-hourglass-end fa-fw text-dark"></i>
                        {{ '\{{weeks}\} седмици' | transloco:{weeks: course?.duration ?? '0'} }}
                    </div>
                    <div class="col-auto my-1">
                        <i class="fa-solid fa-trophy fa-fw text-dark"></i>
                        {{ course?.presents ? ('С награди' | transloco) : ('Без награди' | transloco) }}
                    </div>
                </div>

                <p *ngIf="!pageUrl?.length">
                    {{ ((course?.description ?? '').length > 200)? (course?.description | slice:0:200)+'...':(course?.description) }}
                </p>

                <div class="mt-auto mb-auto mb-lg-0 d-flex justify-content-end w-100" >
                    <div class="btn btn-primary d-lg-block d-none m-2 fw-bold" *ngIf="pageUrl?.length">
                        {{ 'Към курса' | transloco }}
                    </div>
                </div>
                <ng-content></ng-content>
            </div>
        </div>
    </div>


    <div class="card text-center h-100" *ngIf="short">
        <div class="card-body">
            <div class="row">
                <div class="col-auto d-flex flex-column align-items-center my-auto">
                    <avatar [photo]="course?.logo ?? ''" size="small"></avatar>
                    <span class="badge rounded-pill text-bg-danger mt-2" [class.invisible]="course?.status === 'active'">{{ 'Неактивен' | transloco }}</span>
                </div>

                <div class="col">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="card-title text-start mb-1 fw-bold">
                                <ng-container *ngIf="!(user | roleAny:'admin') || !franchiseCourse?.course?.id">{{ course?.title ?? 'Курс' }}</ng-container>
                                <a [routerLink]="'/courses/' + franchiseCourse?.course?.id" *ngIf="franchiseCourse?.course?.id && (user | roleAny:'admin')"
                                    [class.text-decoration-line-through]="franchiseCourse?.course?.status !== 'active'"
                                    [class.text-danger]="franchiseCourse?.course?.status !== 'active'"
                                    [title]="franchiseCourse?.course?.status !== 'active' ? ('Курсът е деактивиран' | transloco) : ('Към курса' | transloco)"
                                > {{ course?.title ?? 'Курс' }}</a>
                            </h5>
                        </div>

                        <div class="col-12">
                            <div class="row justify-content-end">
                                <div class="col d-flex flex-sm-row flex-column text-start card-info-text-wrapper">
                                    <div class="col d-flex flex-column my-1">
                                        <a *ngIf="landingPageUrl?.length"
                                            [href]="landingPageUrl"
                                            target="_blank" (click)="$event.stopPropagation()"
                                        >
                                            <i class="fa-solid fa-link pe-1"></i>{{ course?.url }}
                                        </a>

                                        <ng-container *ngIf="mode === 'edit'">
                                            <p class="m-0 text-muted fst-italic" *ngIf="groupCount">
                                                {{ 'Брой създадени учебни групи:' | transloco }} {{ groupCount }}
                                            </p>
                                        </ng-container>

                                        <ng-container *ngIf="mode === 'view'">
                                            <div class="text-secondary">
                                                <i class="fa-solid fa-tag fa-fw text-dark"></i>
                                                {{ course?.price | price }}
                                            </div>
                                            <div class="text-secondary">
                                                <i class="fa-solid fa-trophy fa-fw text-dark"></i>
                                                {{ course?.presents ? ('С награди' | transloco) : ('Без награди' | transloco) }}
                                            </div>
                                            <div class="text-secondary">
                                                <i class="fa-solid fa-hourglass-end fa-fw text-dark"></i>
                                                {{ '\{{weeks}\} седмици' | transloco:{weeks: course?.duration ?? '0'} }}
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-auto p-0 d-flex align-items-center justify-content-end">
                                    <div class="p-2 my-auto" [title]="'Преглед' | transloco" *ngIf="mode === 'view'">
                                        <i class="icons-p2 fas fa-arrow-circle-right fa-fw fa-lg"></i>
                                    </div>

                                    <a *ngIf="baseEditUrl.length" class="p-2 my-auto remove-btn"
                                            [title]="'Редактиране' | transloco"
                                            (click)="$event.stopPropagation()"
                                            [routerLink]="baseEditUrl"
                                    >
                                        <i class="icons-p2 fa-solid fa-pen-to-square fa-fw fa-lg"></i>
                                    </a>

                                    <ng-container *ngIf="mode === 'edit'">
                                        <a class="p-2 my-auto remove-btn"
                                            [title]="'Премахване' | transloco"
                                            (click)="removeCourse($event,course?.id ?? 0)"
                                            *ngIf="!groupCount"
                                            href=""
                                        >
                                            <i class="icons-p2 fa-regular fa-trash-can fa-fw text-danger fa-lg"></i>
                                        </a>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
