<div class="dropdown">
    <a class="dropdown-toggle mass-options"
        [href]="'/students/' + user?.id"
        data-bs-toggle="dropdown"
        aria-expanded="false"
    >
        <avatar *ngIf="withAvatar" [user]="user" size="tiny" class="me-2"></avatar>
        {{ user | userName }}
    </a>
    <ul class="dropdown-menu">
        <li>
            <a class="dropdown-item" [routerLink]="'/students/' + user?.id" (click)="$event.stopPropagation()" [title]="'Профил' | transloco">
                <avatar [user]="user" size="tiny"></avatar>
                <span class="fw-bold ms-2">{{ user | userName }}</span>
            </a>
        </li>
        <li><hr class="dropdown-divider"></li>

        <li>
            <a class="dropdown-item" routerLink="/messages" [queryParams]="{conversation: user?.id, tab: 'conversations'}" (click)="$event.stopPropagation()">
                <i class="fa-regular fa-envelope fa-fw me-2"></i> {{ 'Напиши съобщение' | transloco }}
            </a>
        </li>
        <li *ngIf="user?.phone?.length || user?.phone?.length"><hr class="dropdown-divider"></li>

        <li *ngIf="user?.phone?.length">
            <a class="dropdown-item"  [href]="'tel:' + user?.phone" target="_blank" (click)="$event.stopPropagation()"  [title]="user?.phone ?? '-'">
                <i class="fa-solid fa-phone fa-fw me-2"></i> {{'Телефон' | transloco }}
            </a>
        </li>
        <li *ngIf="user?.phone?.length"><hr class="dropdown-divider"></li>

        <li *ngIf="user?.email?.length">
            <a class="dropdown-item" [href]="'mailto:' + user?.email" target="_blank" (click)="$event.stopPropagation()" [title]="user?.email ?? '-'">
                <i class="fa-solid fa-at fa-fw me-2" ></i> {{'E-mail' | transloco }}
            </a>
        </li>
    </ul>
</div>

