<div class="card text-center h-100">
    <a class="card-body" [routerLink]="'/groups/' + group?.id">
        <div class="row">
            <div class="col-auto d-flex flex-column align-items-center">
                <avatar [photo]="group?.course?.logo ?? ''" size="small"></avatar>

                <span class="badge rounded-pill text-bg-danger mt-2" [class.invisible]="group?.status === 'active'">{{ 'Неактивен' | transloco }}</span>
            </div>

            <div class="col">
                <div class="row">
                    <div class="col-12 text-start">
                        <h2 class="card-title">
                            {{ group?.course?.title ?? 'Курс' }}
                        </h2>
                        <h3 *ngIf="(user && (user | roleAny: 'admin')) && group?.franchise?.id">{{ group?.franchise?.name }}</h3>
                    </div>

                    <div class="col-12">
                        <div class="row">
                            <div class="col d-flex flex-column text-start card-info-text-wrapper">
                                <h3 class="card-text mb-0" [ngClass]="{
                                    'mb-0': (user && (user | roleAny: 'admin')) && group?.franchise?.id,
                                    'mt-1 mb-2': !(user && (user | roleAny: 'admin')) || !group?.franchise?.id
                                }">
                                    {{ group?.title ?? 'Група' }}
                                </h3>

                                <p class="dates mb-0" *ngIf="group?.parsed_date_start || group?.parsed_date_to" [class.text-muted]="group?.expired" [class.text-secondary]="!group?.expired">
                                    <span class="badge text-bg-secondary align-middle me-1" *ngIf="group?.expired">{{ !isCompetition ? ('Минал курс' | transloco) : ('Минало състезание' | transloco) }}</span>
                                    <small>{{ group?.parsed_date_start | dateLocale:'shortDate' }} - {{ group?.parsed_date_to | dateLocale:'shortDate' }}</small>
                                </p>
                            </div>

                            <div class="col d-flex justify-content-end align-items-center">
                                <a  *ngIf="group?.live?.online && (group?.live?.zoom?.id || group?.live?.google?.length)"
                                    class="p-2 my-auto"
                                    [title]="'На живо' | transloco"
                                    target="_blank"
                                    [href]="'groups/' + group?.id + '/live'"
                                    (click)="$event?.stopPropagation()"
                                >
                                    <i class="icons-p2 fa-regular fa-circle-play fa-fw fa-lg text-danger fa-beat-fade"
                                        style="--fa-beat-fade-opacity: 0.67; --fa-beat-fade-scale: 1.075;"
                                    ></i>
                                </a>

                                <div class="p-2 my-auto" [title]="'Преглед' | transloco">
                                    <i class="icons-p2 fas fa-arrow-circle-right fa-fw fa-lg"></i>
                                </div>

                                <a  class="p-2 my-auto"
                                    [title]="'Редактиране' | transloco"
                                    [routerLink]="'/groups/' + group?.id + '/edit'"
                                    (click)="preventDefault($event)"
                                    *ngIf="withEdit && user && (user | roleAny: 'admin':'partner')"
                                >
                                    <i class="icons-p2 fa-solid fa-pen-to-square fa-fw fa-lg"></i>
                                </a>
                                <a class="p-2 my-auto"
                                    [title]="'Премахване' | transloco"
                                    (click)="onShowConfirm($event)"
                                    *ngIf="withRemove && user && (user | roleAny: 'admin':'partner')"
                                >
                                    <i class="icons-p2 fa-regular fa-trash-can fa-fw text-danger fa-lg"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
</div>

<modal-confirm *ngIf="showConfirm"
               [body]="'Желаете ли да премахнете тази група?' | transloco"
               [headline]="'Премахване на група?' | transloco"
               [skippable]="true"
               (onAction)="onDeleteConfirmAction($event)"
></modal-confirm>
