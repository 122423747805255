import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Paginate } from '../models/paginate.model';
import { Group } from '../models/group.model';
import { ModuleHomework } from '../models/module-homework.model';
import { GroupModule } from '../models/group-module.model';
import { Poll } from '../models/group-poll.model';
import { GroupPollData } from '../models/group-poll-data.model';
import { User } from '../models/user/user.model';
import { FilterSort } from '../models/filter-sort.model';
import { ModuleMaterial } from '../models/module-materials.model';
import { GroupPaymentType } from '../models/group-payment-type.model';
import { GroupEnrollment } from '../models/group-enrollment.model';
import { Project } from '../models/project.model';
import { GroupStudent } from '../models/group-student.model';
import { GroupStudentModule } from '../models/group-student-module.model';

export type GroupServiceIndexIncludeType = (
    'zoomLive'
    | 'projects'
    | 'course'
    | 'students'
    | 'enrollments'
    | 'installments'
    | 'franchise'
);

export type GroupServiceGetItemIncludeType = (
    'students.payments'
);

export type GroupServiceIndexSortType = (
    'id'
    | 'title'
    | 'start_date'
    | 'end_date'
    | 'exam_date'
    | 'certificate_date'
    | 'live'
    | 'status'
    | 'created_at'
    | 'updated_at'
    | 'expiry'
);


export interface UploadHomework {
    name?: string,
    filename?: string,
    mime?: string,
    size?: number,
    description?: string,
    points?: number,
};

export interface VideoInput {
    video?: string,
    description?: string,
    status?: string,
};

export interface GroupServiceInput {
    title?: string;
    description?: string;
    course?: number;
    start?: string;
    end?: string;
    exam?: string;
    certificate?: string;
    facebook?: string;
    google?: string;
    chat?: ('true'|'false');
    price?: number;
    price_online?: number;
    status?: ('active'|'inactive');
    register?: ('open'|'closed');
    certificate_prefix?: number;
    certificate_start?: number;
    online?: boolean;
    zoom?: number;
    room?: number;
    teachers?: number[];
    terms?: string;
    enrollments?: Array<{
        price: number;
        price_online: number;
        date_due: string;
    }>;
    installments?: Array<{
        price: number;
        price_online: number;
        date_due: string;
        description?: string;
        type: number;
    }>;
    student_cap?: number;
};

export interface PollAnswer {
    question: number;
    answer: string;
};

export interface PollAnswers {
    answers: PollAnswer[]
};

export type GroupServiceGroupFilter = {
    limit?: number;
    page?: number;
    q?: string;
    sort?: GroupServiceIndexSortType|FilterSort<GroupServiceIndexSortType>[],
    franchise?: number|null;
    course?: number|null;
    include?: GroupServiceIndexIncludeType | GroupServiceIndexIncludeType[];
    live?: ('online'|'offline');
    expiration?: ('all'|'expired'|'active');
    status?: ('inactive'|'active');
    except?: number[];
};

export type GroupServiceGroupModuleFilter = {
    limit?: number;
    page?: number;
    homework?: ('all'|'sent'|'new');
};

export type GroupServiceModuleFilter = {
    limit?: number;
    page?: number;
    homework?: ('all'|'sent'|'new');
};

export interface GroupServiceInputUpdateModule {
    status?: ('active'|'inactive');
    schedule_date?: string;
    presentation?: {
        filename?: string|null;
        url?: string|null;
        image?: string|null;
        size?: number;
    },
};

// export interface GroupServiceInputUpdateModule {
//     data?: {
//         path?: string|null;
//         url?: string|null;
//         mime?: string|null;
//         size?: number;
//         icon?: string|null;
//     },
// };

export interface GroupServiceInputModule {
    status?: ('active'|'inactive');
    modules: number[];
};

export interface ModuleMaterialsInput {
    name?: string;
    url?: string;
    mime?: string;
};

export interface GroupServicesGetFilter  {
    include?: GroupServiceIndexIncludeType|GroupServiceGetItemIncludeType[];
};

export interface AddGroupProject {
    name?: string;
    description?: string;
    status?: string;
};

export interface GroupStudentServiceMigration {
    group: number;
    students: number[];
};

export interface GroupStudentModuleService {
    user: number;
    modules: number[];
};

export interface UpdateMultipleGroupStudentService {
    type?: ('online'|'class');
    confirmed?: boolean;
    approved?: boolean;
    students?: number[];
};

@Injectable({
    providedIn: 'root'
})
export class GroupService {

    constructor(
        protected api: ApiService,
    ) { }

    /**
     * Get list of groups
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getList(filter?: GroupServiceGroupFilter, maxCacheTime?: number): Observable<Paginate<Group>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as GroupServiceIndexSortType);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(',') as any);

        return this.api.get('/groups', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<Group>(), data);
                data.data = data.data?.map((item: any)=> Group.fromJson(item));
                return data;
            })
        )
    }

    getGroup(id: number, filter?: GroupServicesGetFilter): Observable<{data: Group}> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(',') as any);

        return this.api.get('/groups/' + id, {params: filter}).pipe(
            map(data => {
                data.data = Group.fromJson(data?.data);
                return data;
            })
        );
    }

    addGroup(data: GroupServiceInput): Observable<{data: Group}> {
        return this.api.post('/groups', data).pipe(
            map(data => {
                data.data = Group.fromJson(data?.data);
                return data;
            })
        );
    }

    updateGroup(id: number, data?: GroupServiceInput): Observable<{data: Group}> {
        return this.api.post('/groups/' + id, data).pipe(
            map(data => {
                data.data = Group.fromJson(data?.data);
                return data;
            })
        );
    }

    deleteGroup(id: number):  Observable<any> {
        return this.api.delete('/groups/' + id);
    }

    getModule(id: number, moduleId: number): Observable<{data: GroupModule}> {
        return this.api.get('/groups/'+ id + '/modules/' + moduleId).pipe(
            map(data => {
                data.data = GroupModule.fromJson(data?.data);
                return data;
            })
        );
    }

    getModuleList(id:number, filter?: GroupServiceGroupModuleFilter): Observable<Paginate<GroupModule>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/groups/'+ id + '/modules', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<GroupModule>(), data);
                data.data = data.data?.map((item: any)=> GroupModule.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Add a Module to Group
     *
     * @param id Group ID
     * @param data
     * @returns
     */
    addModule(id: number, data: GroupServiceInputModule): Observable<{data: GroupModule[]}> {
        return this.api.post('/groups/' + id + '/modules', data).pipe(
            map(data => {
                data.data = data?.data?.map((item: any) => GroupModule.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Update Module in Group
     *
     * @param id Group ID
     * @param moduleId Module ID
     * @param data Body Request
     * @returns
     */
    updateModule(id: number, moduleId: number, data: GroupServiceInputUpdateModule): Observable<{data: GroupModule}> {
        return this.api.post('/groups/' + id + '/modules/' + moduleId, data).pipe(
            map(data => {
                data.data = GroupModule.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Delete a Module in Group
     *
     * @param id Group ID
     * @param moduleId Module ID
     * @returns
     */
    deleteModule(id: number, moduleId: number): Observable<{data: any}> {
        return this.api.delete('/groups/' + id + '/modules/' + moduleId);
    }

    addMaterial(id: number, moduleId: number, data: any): Observable<{data: ModuleMaterial}> {
        return this.api.post('/groups/' + id + '/modules/' + moduleId + '/materials', data).pipe(
            map(data => {
                data.data = ModuleMaterial.fromJson(data?.data);
                return data;
            })
        );
    }

    removeMaterial(id: number, moduleId: number, materialId: number):  Observable<any> {
        return this.api.delete('/groups/' + id + '/modules/' + moduleId + '/materials/' + materialId);
    }

    addStudentMultiple(id: number, data: any): Observable<{data: User}> {
        return this.api.patch('/groups/' + id + '/students', data).pipe(
            map(data => {
                data.data = User.fromJson(data?.data);
                return data;
            })
        );
    }

    updateStudent(id: number, studentId: number, data: any): Observable<{data: User}> {
        return this.api.post('/groups/' + id + '/students/' + studentId, data).pipe(
            map(data => {
                data.data = User.fromJson(data?.data);
                return data;
            })
        );
    }

    updateMultipleStudents(id: number, data: UpdateMultipleGroupStudentService): Observable<{data: User}> {
        return this.api.post('/groups/' + id + '/students/batch', data).pipe(
            map(data => {
                data.data = User.fromJson(data?.data);
                return data;
            })
        );
    }

    deleteStudent(id: number, studentId: number): Observable<{data: any}> {
        return this.api.delete('/groups/' + id + '/students/' + studentId);
    }

    migrateStudent(id: number, data: GroupStudentServiceMigration): Observable<{data: GroupStudent[]}> {
        return this.api.post('/groups/' + id + '/students/migrations', data).pipe(
            map(data => {
                data.data = data.data?.map((item: any) => GroupStudent.fromJson(item));
                return data;
            })
        );
    }

    getPollList(id:number, pollId: number): Observable<{data:Poll}> {
        return this.api.get('/groups/'+ id + '/polls/' + pollId).pipe(
            map(data => {
                data = Object.assign(data.data);
                return data;
            })
        );
    }
    setPollList(id:number, pollId:number, data:GroupPollData ): Observable<{data:GroupPollData}> {
        return this.api.post('/groups/'+ id + '/polls/' + pollId, data)
        .pipe(
            map(data => {
                data.data = Poll.fromJson(data?.data)
                return data;
            })
        );
    }
    uploadHomework(group_id: number, homework_id: number, data: UploadHomework): Observable<{data: any}> {
        return this.api.post<{data: any}>('/groups/'+ group_id +'/homeworks/' + homework_id, data)
    }

    getInstallmentTypeList(maxCacheTime?: number): Observable<Paginate<GroupPaymentType>> {
        return this.api.get('/groups/payments/types', null, maxCacheTime).pipe(
            map(data => {
                data.data = data.data?.map((item: any)=> GroupPaymentType.fromJson(item));
                return data;
            })
        )
    }

    getCurrentEnrollment(group: Group) : GroupEnrollment | null {
        if (group?.enrollments?.length) {
            const futureEnrollments = group?.enrollments
              .filter(enrollment => enrollment.date && new Date(enrollment.date) > new Date());

            return futureEnrollments[futureEnrollments.length - 1];
        }
        return null;
    }

    createGroupProject(groupId: number, projectData: AddGroupProject): Observable<{data: Project}> {
        return this.api.post('/groups/' + groupId + '/projects', projectData).pipe(
            map(data => {
                data.data = Project.fromJson(data.data);
                return data;
            })
        );
    }

    editGroupProject(groupId: number, projectId: number, projectData: AddGroupProject): Observable<{data: Project}> {
        return this.api.post('/groups/' + groupId + '/projects/' + projectId, projectData).pipe(
            map(data => {
                data.data = Project.fromJson(data.data);
                return data;
            })
        );
    }

    deleteGroupProject(groupId: number, projectId: number): Observable<{data: any}> {
        return this.api.delete('/groups/' + groupId + '/projects/' + projectId);
    }

    editGroupUserModuleAccess(id: number, data: GroupStudentModuleService): Observable<{data: GroupStudentModule[]}> {
        return this.api.post('/groups/' + id + '/modules/accessibility', data).pipe(
            map(data => {
                data.data = data.data?.map((item: any) => GroupStudentModule.fromJson(item));
                return data;
            })
        );
    }
}
