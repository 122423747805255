<div class="calendar {{ className }} position-relative" [class.embed]="variant === 'embed'">
    <loading [loading]="loading" [hasData]="true" [parentWrapped]="true"></loading>
    <full-calendar [options]="calendarOptions"></full-calendar>
</div>

<p-dialog
    [(visible)]="showDetailsModal" [contentStyle]="{'max-height': '600px', 'overflow': 'auto'}"
    [modal]="true"
    [draggable]="false"
    (onShow)="onDialogToggle($event)"
    (onHide)="onDialogToggle($event)"
    [style]="{'width':'fit-content', 'max-width': '80vw'}"
    [header]="((selectedDate ?? '') | dateLocale:'dd MMMM') || ''"
>

    <ng-template pTemplate>
        <ng-container *ngFor="let event of eventsForDate; let index = index">
            <div class="event-description row d-nowrap" [class.py-4]="eventsForDate?.length <= 2">
                <div class="my-1 col-sm-6 col-12 mx-0">
                    <div class="pe-3">
                        <strong class="event-title">{{ event.title }}:</strong>
                        <p class="event-title-p mb-0 me-1">
                            <a [routerLink]="'/groups/' + event?.details?.id" [title]="'Към курса' | transloco">
                                {{ event?.details?.course?.title }}
                            </a>
                        </p>
                    </div>

                    <div class="btn-group position-absolute actions-dropdown" *ngIf="mode === 'edit'">
                        <button class="btn btn-default btn-sm dropdown-toggle no-caret py-0"
                                type="button"
                                data-bs-boundary="document"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                        >
                            <i class="fa-solid fa-gear fa-fw fa-lg"></i>
                        </button>

                        <ul class="dropdown-menu">
                            <li>
                                <button type="button" class="dropdown-item" (click)="onEdit($event, event)">
                                    <i class="fa-solid fa-pen-to-square fa-fw"></i>
                                    {{ 'Редактиране' | transloco }}
                                </button>
                            </li>
                            <li><hr class="dropdown-divider"></li>
                            <li>
                                <button type="button" class="dropdown-item" (click)="onDelete($event, event)">
                                    <i class="fas fa-trash-can fa-fw text-danger"></i>
                                    {{ 'Премахване' | transloco }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clock col-sm-6 col-12 ps-md-4 mx-0">
                    <div class="circle">
                        <i class="icon icon-event-clock"></i>
                    </div>

                    <span class="badge rounded-pill text-bg-primary " [class.py-2]="event?.details?.room?.title?.length">
                        {{ event.start | dateLocale:'shortTime' }} - {{ event.end | dateLocale:'shortTime'  }}

                        <strong *ngIf="event?.details?.room?.title?.length"
                            class="text-start small d-block text-truncate"
                            [ngStyle]="{'max-width': '125px'}"
                        >
                            {{ event?.details?.room?.title }}
                        </strong>
                    </span>
                </div>
            </div>
            <hr class="divider" *ngIf="(index + 1) != eventsForDate.length">
        </ng-container>
    </ng-template>

    <ng-template pTemplate="footer" *ngIf="mode === 'edit'" >
        <button type="button" class="btn btn-primary btn-sm" (click)="onAdd($event)">
            <i class="fas fa-plus fa-fw"></i>
            {{ 'Добави друго събитие' | transloco}}
        </button>
    </ng-template>
</p-dialog>

<modal-confirm *ngIf="showDeleteConfirm"
               [headline]="'Премахване на събитие' | transloco"
               [body]="'Желаете ли да премахнете това събитие?' | transloco"
               [skippable]="true"
               (onAction)="onDeleteConfirmed($event)"
               (onShow)="onDialogToggle($event)"
               (onHide)="onDialogToggle($event)"

>
    <p-multiSelect *ngIf="otherEvents?.length"
                    class="mt-4 mb-4 d-block"
                    [(ngModel)]="selectedOtherEvents"
                    [options]="otherEvents || []"
                    optionLabel="title"
                    optionValue="id"
                    appendTo="body"

                    [defaultLabel]="'Други събития в групата за изтриване?' | transloco"
                    [emptyFilterMessage]="'Няма намерени резултати' | transloco"
                    [emptyMessage]="'Няма намерени резултати' | transloco"
    >
        <ng-template let-event pTemplate="item">
            <div class="d-flex align-items-start flex-column overflow-hidden text-truncate">
                {{ event.title ?? '' }}
                <small [class.text-muted]="(selectedOtherEvents || []).indexOf(event?.id) < 0">{{ event.start | dateLocale}} - {{ event.end | dateLocale}}</small>
            </div>
        </ng-template>
    </p-multiSelect>
</modal-confirm>
