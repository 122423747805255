
<p-dialog
    [visible]="true"
    (visibleChange)="onCloseAction($event)"
    [closable]="closable"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '40vw', 'width': '991px', 'max-width': '90vw'}"
    styleClass="mx-3 mx-sm-0"
    [header]="headline?.length ? headline : '&nbsp;'"
>
    <div class="payment-cover">
        <img src="/assets/img/icons/payment-popup.svg" alt="payment main icon">
    </div>
    <div class="payment-content">

        <div class="row p-0 m-0">
            <div class="col-12 text-center sub-title p-0 m-0 mb-3" *ngIf="payment.price?.bgn">
                <h2 class="m-0 py-2">
                    {{payment.description ? payment.description + ' - ' : (payment?.type?.title ? payment?.type?.title + ' - ' : '') }}
                    <span> {{payment.price?.bgn | price }}</span>
                </h2>
            </div>

            <alert [type]="messageType" [message]="message" (remove)="message = ''"></alert>

            <div class="col-12 text-center mb-3">
                <p *ngIf="paymentMethodsCount">
                    {{ 'payments.ways_to_pay' | transloco:{count: paymentMethodsCount} }}
                </p>
            </div>
        </div>

        <div class="row w-100">
            <div class="col-12">
                <loading [loading]="loading" />
            </div>
        </div>


        <div class="row p-0 m-0">
            <div class="col-12 col-lg-10 mx-auto" [class.payments-wrap]="paymentMethodsCount > 0 || franchise?.primary" [class.text-center]="franchise?.primary">
                <div class="payment-nav d-flex flex-lg-row flex-column align-items-center pb-3 mb-3" *ngIf="paymentMethodsCount > 0 || franchise?.primary">
                    <div class="flex-fill mx-2 d-flex mb-lg-0 mb-2 justify-content-center"
                        *ngIf="allowedPaymentMethods.cash || franchise?.primary"
                        data-bs-toggle="collapse"
                        data-bs-target="#paymentTypeCash"
                        [attr.aria-expanded]="showPaymentByDefault === 'cash'"
                        aria-controls="paymentTypeCash"
                        [class.collapsed]="showPaymentByDefault === 'cash'"
                    >

                        <i class="fa-solid fa-square-check align-self-center mx-1 check"></i>
                        <i class="fa-regular fa-square align-self-center mx-1 uncheck"></i>

                        <button class="btn btn-primary mx-1 d-flex mb-lg-0 mb-2 text-md-center" type="button">
                            <ng-container *ngIf="franchise?.primary">
                                <i class="icon align-self-center icon-academy-a me-2"></i>
                                <span class="align-self-center d-lg-flex d-none">{{ 'Офис на' | transloco }} Advance&nbsp;Academy</span>
                                <span class="align-self-center d-lg-none d-flex ms-3">{{ 'В офис' | transloco }}</span>
                            </ng-container>
                            <ng-container *ngIf="!franchise?.primary">
                                <i *ngIf="!franchise?.primary" class="icon align-self-center fa-solid fa-money-bill fa-fw py-2 my-1"></i>
                                <span *ngIf="!franchise?.primary" class="align-self-center d-flex ms-3">{{ 'В брой' | transloco }}</span>
                            </ng-container>
                        </button>
                    </div>

                    <div class="flex-fill mx-2 d-flex mb-lg-0 mb-2 justify-content-center"
                        *ngIf="allowedPaymentMethods.bank || franchise?.primary"
                        data-bs-toggle="collapse"
                        data-bs-target="#paymentTypeBank"
                        [attr.aria-expanded]="showPaymentByDefault === 'bank'"
                        aria-controls="paymentTypeBank"
                        [class.collapsed]="showPaymentByDefault === 'bank'"
                    >

                        <i class="fa-solid fa-square-check align-self-center mx-1 check"></i>
                        <i class="fa-regular fa-square align-self-center mx-1 uncheck"></i>

                        <button class="btn btn-primary mx-1 d-flex mb-lg-0 mb-2 text-md-center" type="button">
                            <i class="icon align-self-center icon-bank me-2"></i>
                            <span class="align-self-center d-lg-flex d-none">{{ 'По банков път' | transloco }}</span>
                            <span class="align-self-center d-lg-none d-flex ms-3">{{ 'По банка' | transloco }}</span>
                        </button>
                    </div>

                    <div class="flex-fill mx-2 d-flex mb-lg-0 mb-2 justify-content-center"
                        *ngIf="allowedPaymentMethods.mypos || allowedPaymentMethods.paypal || franchise?.primary"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#paymentTypeOnline"
                        [attr.aria-expanded]="showPaymentByDefault === 'online'"
                        aria-controls="paymentTypeOnline"
                        [class.collapsed]="showPaymentByDefault === 'online'"
                    >

                        <i class="fa-solid fa-square-check align-self-center mx-1 check"></i>
                        <i class="fa-regular fa-square align-self-center mx-1 uncheck"></i>

                        <button class="btn btn-primary mx-1 d-flex mb-lg-0 mb-2" type="button">
                            <i class="icon align-self-center icon-exchange me-2"></i>
                            <span class="align-self-center d-lg-flex d-none">{{ 'Чрез онлайн трансфер' | transloco }}</span>
                            <span class="align-self-center d-lg-none d-flex ms-3">{{ 'Онлайн' | transloco }}</span>
                        </button>
                    </div>
                </div>

                <div id="paymentTabs" class="payment-tabs pt-4" *ngIf="paymentMethodsCount > 0 || franchise?.primary">
                    <div class="payment-tab">
                        <div class="row">
                            <div class="col-12">
                                <div class="collapse multi-collapse office-info mb-3 text-break" [class.show]="showPaymentByDefault === 'cash'" id="paymentTypeCash" data-bs-parent="#paymentTabs">
                                   <div *ngIf="franchise?.primary">
                                        <p class="m-0 mb-2" [innerHTML]="'Може да платите при нас в \<span>брой</span> или чрез \<span>карта\</span>' | transloco"></p>
                                        <h3 class="m-0">{{ 'Можете да ни намерите на адрес:' | transloco }}</h3>
                                        <a href="https://goo.gl/maps/Ca6RxaoHb6oT4iR68"
                                            class="mb-4 d-flex justify-content-center"
                                            target="_blank"
                                        >
                                            гр.Варна, ул. Димитър Икономов № 19, ет.3
                                        </a>
                                        <h3 class="mb-1">{{ 'Работно време:' | transloco }}</h3>
                                        <p class="m-0">Понеделник - Петък / 9:30ч. - 18:00ч.</p>
                                        <p class="m-0">Събота и Неделя / почивни дни</p>
                                   </div>

                                   <div *ngIf="!franchise?.primary" [innerHTML]="franchiseOfficeInformation | safe:'html'"></div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="collapse multi-collapse text-break" [class.show]="showPaymentByDefault === 'bank'" id="paymentTypeBank" data-bs-parent="#paymentTabs">
                                    <div class="row" *ngIf="franchise?.primary">
                                        <div class="col-8 text-center bank-title">
                                            <h2 class="d-flex justify-content-center">
                                                <i class="icon-bank me-1"></i>
                                                <span>{{ 'Плащане по банков път'| transloco }}</span>
                                            </h2>
                                            <p>{{ 'Плащането по банков път трябва да е в полза на получателя:' | transloco }} <br> <span> “Адванс Хюмън Кепитал” ООД</span></p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="franchise?.primary">
                                        <div class="col-12">
                                            <div class="col-8 bank-info">
                                                <dl class="row w-auto">
                                                    <dd class="col-sm-6">{{ 'Банка на получателя:' | transloco }}</dd>
                                                    <dt class="col-sm-6">Банка ДСК ЕАД</dt>

                                                    <dd class="col-sm-6">{{ 'IBAN:' | transloco }}</dd>
                                                    <dt class="col-sm-6">BG71 STSA 9300 0023 5370 26</dt>

                                                    <dd class="col-sm-6">{{ 'BIC:' | transloco }}</dd>
                                                    <dt class="col-sm-6">STSABGSF</dt>

                                                    <dd class="col-sm-6">{{ 'Наредител:'| transloco }}</dd>
                                                    <dt class="col-sm-6">{{ 'Три имена'| transloco }}</dt>

                                                    <dd class="col-sm-6">{{ 'Основание:' | transloco}}</dd>
                                                    <dt class="col-sm-6">{{ 'Наименование на обучението' | transloco }}</dt>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="!franchise?.primary" [innerHTML]="franchiseBankInformation | safe:'html'"></div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="collapse multi-collapse" [class.show]="showPaymentByDefault === 'online'" id="paymentTypeOnline" data-bs-parent="#paymentTabs">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <h2 class="my-2">
                                                <i class="icon-exchange"></i>
                                                {{ 'Плащане чрез онлайн трансфер' | transloco }}
                                            </h2>
                                        </div>

                                        <div class="col-12 flex-md-row flex-column d-flex justify-content-center align-items-center payment-buttons" [class.loading]="loading">
                                            <div class="mypos-button position-relative pay-btn my-3 mx-0 m-sm-3 d-flex justify-content-center align-items-center order-2 order-md-1" [class.loading]="loading" (click)="onBuyConfirmed($event)" *ngIf="allowedPaymentMethods.mypos || franchise?.primary">
                                                <div class="fake-button text-md-center">
                                                    <i class="icon-card me-2"></i>
                                                    {{ 'С карта' | transloco }}
                                                </div>
                                            </div>
                                            <div class="paypal-button pay-btn my-3 mx-0 m-sm-3 order-1 order-md-2" *ngIf="allowedPaymentMethods.paypal || franchise?.primary">
                                                <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                                                <div class="fake-button d-flex justify-content-center align-items-center">
                                                    <i class="icon-paypal me-2"></i>
                                                    {{ 'PayPal' | transloco }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert alert-warning" *ngIf="paymentMethodsCount === 0 && !loading && !franchise?.primary">
                    {{'Относно заплащането, моля, свърежете се с нас за допълнителна информация.' | transloco}}
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="d-none" #form [innerHTML]="paymentForm | safe:'html'"></div>
    </div>

    <p-footer class="p-0">
        <div class="row p-0 m-0">
            <div class="col-lg-10 col-12 text-center additional-info px-2">
                <p class="mt-2 py-3 mb-0" *ngIf="franchise?.phone">
                    {{ 'При въпроси и затруднение можете да се свържете с нас на тел:' | transloco }} <a href="tel:{{franchise?.phone}}">{{franchise?.phone}}</a>
                </p>
            </div>
        </div>
    </p-footer>
</p-dialog>
