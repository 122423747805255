<div class="pill" [class.clickable]="clickable" (click)="onClick($event)">
    <div class="row m-0 align-items-center justify-content-between" [class.text-nowrap]="noWrapText" >
        <div class="left col p-0 align-self-stretch align-items-center d-flex text-center" [class.text-nowrap]="noWrapText" [class.left]="!flip" [class.right]="flip">
            <div class="px-2 py-1 fw-normal">&nbsp;{{ left ?? "" }}&nbsp;</div>
        </div>
        <div class="right col p-0 align-self-stretch align-items-center d-flex text-center" [class.text-nowrap]="noWrapText" [class.left]="flip" [class.right]="!flip">
            <div class="px-2 py-1 fw-bold">&nbsp;{{ right ?? "" }}&nbsp;</div>
        </div>
    </div>
</div>
