import { Component, Input } from '@angular/core';
import { User } from '../../models/user/user.model';

@Component({
    selector: 'student-contact',
    templateUrl: './student-contact.component.html',
    styleUrls: ['./student-contact.component.scss']
})
export class StudentContactComponent {

    @Input() user: User|null = null;
    @Input() withAvatar: boolean = false;
}
