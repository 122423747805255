export type CourseStatus = ('active'|'inactive');
import moment from "moment";
import { CourseDesign } from "./course-design.model";
import { CourseSpotlight } from "./course/course-spotlight.model";
import { CourseTopic } from "./course/course-topic.model";
import { Module } from "./module.model";
import { CoursePreview } from "./course/course-preview.model";

export class Course {
    id?: number;
    title?: string;
    description?: string;
    url?: string;
    price?: number;
    presents?: boolean;
    status?: CourseStatus;
    duration?: number;
    starting?: string;
    image?: string;
    logo?: string;
    badge?: string;
    image_share?: string;
    badge_alt?: string;
    design?: CourseDesign;
    available?: boolean;
    level?: number;
    position?: number;
    position_Footer?: number;
    created?: string;
    updated?: string;
    modules?: Module[];
    groups_count?: number;
    modules_count?: number;
    topics?: CourseTopic[];
    previews?: CoursePreview[];

    spotlights?: CourseSpotlight[];

    parsed_starting?: Date;

    static fromJson(fields: any): Course {
        let item = Object.assign(new this(), fields);
        item.modules = fields?.modules?.map((modules: Module) => Module.fromJson(modules));
        item.design = CourseDesign.fromJson(fields?.design);
        item.topics = fields?.topics?.map((item: any) => CourseTopic.fromJson(item));
        item.topics = fields?.topics?.map((item: any) => CoursePreview.fromJson(item));


        [
            'starting',
        ].forEach(name => {
            item['parsed_' + name] = moment.utc(fields?.[name], true);
            item['parsed_' + name] =  item['parsed_' + name]?.isValid() ? item['parsed_' + name]?.toDate() : null;
        });

        return item;
    }

    static getStatusTitle(status: CourseStatus = 'active')
    {
        const statuses: {[name in CourseStatus]?: string} = {
            'active': 'Активен',
            'inactive': 'Неактивен'
        };

        return statuses[status] ?? '';
    }

}
