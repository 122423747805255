    <alert [type]="messageType" [message]="message" (remove)="message = ''"></alert>

    <form *ngIf="form" (submit)="onSubmit($event)" [formGroup]="form" class="profile-form standard-form mb-5" autocomplete="off">

        <div class="card mb-3 mb-lg-5" *ngIf="mode === 'add'">
            <div class="card-header">
                <div class="row">
                    <div class="col-6">
                        <h3 class="my-0 fw-bold">{{ 'Потребител за администрация' | transloco }}</h3>
                    </div>
                    <div class="col-6">
                        <div class="align-items-end text-end justify-content-end d-flex" [class.is-invalid]="errors?.status?.status === 'INVALID'">
                            <div class="form-check form-switch d-inline-block" [class.is-invalid]="errors?.status?.status === 'INVALID'">
                                <input  class="form-check-input"
                                        type="checkbox"
                                        id="status"
                                        formControlName="status"
                                        [checked]="form.controls['status'].value" />
                                <label class="form-check-label" for="status">{{ form.controls['status'].value ? ('Активен статус'|transloco) : ('Неактивен статус'|transloco) }}</label>
                            </div>
                        </div>

                        <div class="invalid-feedback" *ngIf="errors?.status?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.status?.errors?.pattern">{{ 'Невалидна стойност' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.status?.errors?.any">{{ errors?.status?.errors?.any }}</div>
                    </div>
                </div>
            </div>
            <div class="card-body">

                <div class="col-12 text-start mb-5">
                    <upload-avatar (onFinished)="onFilePartnerFinished($event)"
                                   (onRemove)="photo = {}"
                                   [withRemove]="true"
                                   [avatar]="photo"
                                   size="big"
                                   [user]="partner"
                                   [class.is-invalid]="errors?.avatar?.status === 'INVALID'"
                    ></upload-avatar>

                    <div class="invalid-feedback" *ngIf="errors?.avatar?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                    <div class="invalid-feedback" *ngIf="errors?.avatar?.errors?.any">{{ errors?.avatar?.errors?.any }}</div>
                </div>

                <div class="col-12">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label for="name">{{ 'Име:' | transloco }}</label>
                                <input requiredField
                                        type="text"
                                        id="name"
                                        class="form-control my-2"
                                        name="name"
                                        [placeholder]="'напр. Иван' | transloco"
                                        formControlName="name"
                                        [attr.disabled]="loading ? true : null"
                                        [class.is-invalid]="errors?.name?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.name?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.name?.errors?.minlength && !errors?.name?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.name?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.name?.errors?.any">{{ errors?.name?.errors?.any }}</div>
                            </div>
                        </div>

                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label for="surname">{{ 'Презиме:' | transloco }}</label>
                                <input requiredField
                                        type="text"
                                        id="surname"
                                        class="form-control my-2"
                                        name="surname"
                                        placeholder="{{ 'напр. Иванов' | transloco }}"
                                        formControlName="surname"
                                        [attr.disabled]="loading ? true : null"
                                        [class.is-invalid]="errors?.surname?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.surname?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.surname?.errors?.minlength && !errors?.surname?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.surname?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.surname?.errors?.any">{{ errors?.surname?.errors?.any }}</div>
                            </div>
                        </div>

                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label for="lastname">{{ 'Фамилия:' | transloco }}</label>
                                <input requiredField
                                        type="text"
                                        id="lastname"
                                        class="form-control my-2"
                                        name="lastname"
                                        placeholder="{{ 'напр. Иванов' }}"
                                        formControlName="lastname"
                                        [attr.disabled]="loading ? true : null"
                                        [class.is-invalid]="errors?.lastname?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.lastname?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.lastname?.errors?.minlength && !errors?.lastname?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.lastname?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.lastname?.errors?.any">{{ errors?.lastname?.errors?.any }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label for="inputEmail">{{ 'E-mail:' | transloco }}</label>
                                <input  requiredField
                                        type="text"
                                        autocomplete="off"
                                        aria-autocomplete="none"
                                        class="form-control my-2"
                                        id="inputEmail"
                                        [attr.disabled]="loading ? true : null"
                                        formControlName="email"
                                        placeholder="admin@example.com"
                                        [class.is-invalid]="errors?.email?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.email?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.email?.errors?.notunique">{{ 'Адресът е зает' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.email?.errors?.pattern || errors?.email?.errors?.email">{{ 'Недостатъчно символи' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.email?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.email?.errors?.any">{{ errors?.email?.errors?.any }}</div>
                            </div>
                        </div>

                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label for="inputPassword">{{ 'Парола:' | transloco }}</label>
                                <input  requiredField
                                        type="password"
                                        autoComplete="new-password"
                                        aria-autocomplete="none"
                                        class="form-control my-2"
                                        id="inputPassword"
                                        [attr.disabled]="loading ? true : null"
                                        formControlName="password"
                                        placeholder=""
                                        [class.is-invalid]="errors?.password?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.password?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.password?.errors?.minlength && !errors?.password?.errors?.required">{{ 'Полето не може да е по-малко от 8 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.password?.errors?.any">{{ errors?.password?.errors?.any }}</div>
                            </div>
                        </div>

                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label for="inputPhone">{{ 'Телефон:' | transloco }}</label>
                                <input requiredField
                                        type="text"
                                        class="form-control my-2"
                                        id="inputPhone"
                                        [attr.disabled]="loading ? true : null"
                                        formControlName="phone"
                                        placeholder="+359 123-456-789"
                                        [class.is-invalid]="errors?.phone?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.phone?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.phone?.errors?.maxlength">{{ 'Не може да е повече от 20 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.phone?.errors?.any">{{ errors?.phone?.errors?.any }}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="card mb-3 mb-lg-5">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="my-0 fw-bold">{{ 'Академия' | transloco }}</h3>
                    </div>

                    <div class="col-md-6" *ngIf="(user | roleAny:'admin')">
                        <div class="align-items-end text-end justify-content-end d-flex" [class.is-invalid]="errors?.status?.status === 'INVALID'">
                            <div class="form-check form-switch d-inline-block" [class.is-invalid]="errors?.status?.status === 'INVALID'">
                                <input  class="form-check-input"
                                        type="checkbox"
                                        id="franchise_status"
                                        formControlName="franchise_status"
                                        [checked]="form.controls['franchise_status'].value" />
                                <label class="form-check-label" for="franchise_status">{{ form.controls['franchise_status'].value ? ('Активен статус'|transloco) : ('Неактивен статус'|transloco) }}</label>
                            </div>
                        </div>

                        <div class="invalid-feedback" *ngIf="errors?.franchise_status?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_status?.errors?.pattern">{{ 'Невалидна стойност' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_status?.errors?.any">{{ errors?.status?.errors?.any }}</div>
                    </div>
                </div>
            </div>
            <div class="card-body">

                <div class="row mb-3">
                    <div class="col-md-auto mb-3">
                        <div class="row"><label class="col-12 mb-2" for="logo">{{ 'Лого:' | transloco }}</label></div>
                        <input type="hidden" requiredField requiredFieldAttribute="logo" formControlName="logo" />
                        <upload-image

                                        (onFinished)="onFileFranchiseFinished($event, 'logo')"
                                        (onRemove)="logo = {};"
                                        [withRemove]="true"
                                        [labelDefault]="'Добави лого' | transloco"
                                        [loading]="loading"
                                        [class.is-invalid]="errors?.logo?.status === 'INVALID'"
                                        [preview]="logo?.url ?? ''"
                        ></upload-image>

                        <div class="invalid-feedback" *ngIf="errors?.logo?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.logo?.errors?.any">{{ errors?.logo?.errors?.any }}</div>
                    </div>

                    <div class="col-md-auto mb-3" id="icon">
                        <div class="mx-4">
                            <div class="row"><label class="col-12 mb-2" for="icon">{{ 'Икона (квадратно лого):' | transloco }}</label></div>
                            <input type="hidden" requiredField requiredFieldAttribute="icon" formControlName="icon" />

                            <upload-image   (onFinished)="onFileFranchiseFinished($event, 'icon')"
                                            (onRemove)="icon = {};"
                                            [withRemove]="true"
                                            [labelDefault]="'Добави икона' | transloco"
                                            [loading]="loading"
                                            [class.is-invalid]="errors?.icon?.status === 'INVALID'"
                                            [preview]="icon?.url ?? ''"
                            ></upload-image>

                            <div class="invalid-feedback" *ngIf="errors?.icon?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.icon?.errors?.any">{{ errors?.icon?.errors?.any }}</div>
                        </div>
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-md-6" *ngIf="(user | roleAny:'admin')">
                        <label for="plan">{{ 'План:' | transloco }}</label>
                        <p-dropdown requiredField
                                    requiredFieldAttribute="plan"
                                    [class.is-invalid]="errors?.franchise_plan?.status === 'INVALID'"
                                    [options]="planList"
                                    [(ngModel)]="selectedPlan"
                                    [placeholder]="'< ' + ('Моля, изберете план' | transloco) + ' >'"
                                    formControlName="franchise_plan"
                                    optionValue="id"
                                    optionLabel="title"
                                    styleClass="w-100"
                                    inputId="plan"
                                    appendTo="body"
                        ></p-dropdown>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_plan?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_plan?.errors?.any">{{ errors?.franchise_plan?.errors?.any }}</div>

                    </div>

                    <div class="col-md-6" [class.col-md-6]="(user | roleAny:'admin')" [class.col-12]="(user | roleAny:'partner')">
                        <label for="franchise_url">{{ 'Домейн:' | transloco }}</label>
                        <input type="text"
                                requiredField
                                requiredFieldAttribute="franchise_url"
                                [requiredFieldClass]="(user | roleAny:'admin') ? 'required' : ''"
                                id="franchise_url"
                                class="form-control my-2"
                                name="franchise_url"
                                placeholder="www.example.com"
                                formControlName="franchise_url"
                                [class.is-invalid]="errors?.franchise_url?.status === 'INVALID'"
                                [attr.disabled]="(user | roleAny:'partner')"
                                [readonly]="(user | roleAny:'partner')"
                        />
                        <div class="invalid-feedback" *ngIf="errors?.franchise_url?.errors?.pattern">{{ 'Невалидни символи' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_url?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_url?.errors?.minlength && !errors?.franchise_url?.errors?.required">{{ 'Полето не може да е по-кратко от 2 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_url?.errors?.maxlength">{{ 'Полето надвишава 255 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_url?.errors?.any">{{ errors?.franchise_url?.errors?.any }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="franchise_name">{{ 'Име на академия:' | transloco }}</label>
                        <input type="text"
                                requiredField requiredFieldAttribute="franchise_name"
                                id="franchise_name"
                                class="form-control my-2"
                                name="franchise_name"
                                [placeholder]="'Напр. Advance Academy' | transloco"
                                formControlName="franchise_name"
                                [class.is-invalid]="errors?.franchise_name?.status === 'INVALID'"
                        />
                        <div class="invalid-feedback" *ngIf="errors?.franchise_name?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_name?.errors?.minlength && !errors?.franchise_name?.errors?.required">{{ 'Полето не може да е по-кратко от 2 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_name?.errors?.maxlength">{{ 'Не може да е повече от 100 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_name?.errors?.any">{{ errors?.franchise_name?.errors?.any }}</div>
                    </div>


                    <div class="col-lg-6 mb-3">
                        <div class="d-flex align-items-center justify-content-between">
                            <label for="franchise_email">{{ 'E-mail:' | transloco }}</label>
                            <small class="ms-auto text-muted">{{ '(Публичен)' | transloco }}</small>
                        </div>
                        <input  requiredField requiredFieldAttribute="franchise_email"
                                type="email"
                                id="franchise_email"
                                class="form-control my-2"
                                name="franchise_email"
                                placeholder="contact@example.com"
                                formControlName="franchise_email"
                                [class.is-invalid]="errors?.franchise_email?.status === 'INVALID'"
                        />
                        <div class="invalid-feedback" *ngIf="errors?.franchise_email?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.franchise_email?.errors?.notunique">{{ 'Адресът е зает' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.franchise_email?.errors?.email">{{ 'Невалиден адрес' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.franchise_email?.errors?.pattern || errors?.franchise_email?.errors?.franchise_email">{{ 'Недостатъчно символи' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.franchise_email?.errors?.maxlength">{{ 'Не може да е повече от 100 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.franchise_email?.errors?.any">{{ errors?.franchise_email?.errors?.any }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 mb-3">
                        <div class="form-group d-flex flex-column">
                            <label for="town">{{ 'Населено място:' | transloco }}</label>
                            <p-dropdown inputId="town"
                                        requiredField requiredFieldAttribute="franchise_town"
                                        [class.is-invalid]="errors?.franchise_town?.status === 'INVALID'"
                                        [filter]="true"
                                        [options]="townList"
                                        [(ngModel)]="selectedTown"
                                        formControlName="franchise_town"
                                        optionValue="id"
                                        optionLabel="name"
                                        class="dropdown my-2 w-100"
                                        styleClass="w-100"
                                        [emptyFilterMessage]="'Населеното място не е намерено' | transloco"
                                        [placeholder]="'< ' + ('Моля, изберете населено място' | transloco) + ' >'"
                            ></p-dropdown>
                            <div class="invalid-feedback dropdown-feedback" *ngIf="errors?.franchise_town?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                            <div class="invalid-feedback dropdown-feedback" *ngIf="errors?.franchise_town?.errors?.pattern">{{ 'Невалидна стойност' | transloco }}</div>
                            <div class="invalid-feedback dropdown-feedback" *ngIf="errors?.franchise_town?.errors?.any">{{ errors?.active?.errors?.any }}</div>
                        </div>
                    </div>

                    <div class="col-lg-6 mb-3">
                        <label for="franchise_address">{{ 'Адрес:' | transloco }}</label>
                        <input  requiredField requiredFieldAttribute="franchise_address"
                                type="text"
                                id="franchise_address"
                                class="form-control my-2"
                                name="franchise_address"
                                [placeholder]="'Улица, номер, етаж..' | transloco"
                                formControlName="franchise_address"
                                [class.is-invalid]="errors?.franchise_address?.status === 'INVALID'"
                        />
                        <div class="invalid-feedback" *ngIf="errors?.franchise_address?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_address?.errors?.minlength && !errors?.franchise_address?.errors?.required">{{ 'Полето не може да е по-кратко от 2 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_address?.errors?.maxlength">{{ 'Полето надвишава 100 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_address?.errors?.any">{{ errors?.franchise_address?.errors?.any }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 mb-3">
                        <div class="d-flex align-items-center justify-content-between">
                            <label for="franchise_phone">{{ 'Телефон:' | transloco }}</label>
                            <small class="ms-auto text-muted">{{ '(Публичен)' | transloco }}</small>
                        </div>
                        <input  requiredField requiredFieldAttribute="franchise_phone"
                                type="text"
                                id="franchise_phone"
                                class="form-control my-2"
                                name="franchise_phone"
                                placeholder="+359 123-456-789"
                                formControlName="franchise_phone"
                                [class.is-invalid]="errors?.franchise_phone?.status === 'INVALID'"
                        />
                        <div class="invalid-feedback" *ngIf="errors?.franchise_phone?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_phone?.errors?.minlength && !errors?.franchise_phone?.errors?.required">{{ 'Полето не може да е по-кратко от 2 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_phone?.errors?.maxlength">{{ 'Полето надвишава 100 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_phone?.errors?.any">{{ errors?.franchise_phone?.errors?.any }}</div>
                    </div>

                    <div class="col-lg-6 mb-3" *ngIf="!franchise?.primary">
                        <div class="d-flex align-items-center justify-content-between">
                            <label for="franchise_facebook">{{ 'Facebook страница:' | transloco }}</label>
                            <small class="ms-auto text-muted">{{ '(Публичен)' | transloco }}</small>
                        </div>
                        <input  requiredField requiredFieldAttribute="franchise_facebook"
                                type="text"
                                id="franchise_facebook"
                                class="form-control my-2"
                                name="franchise_facebook"
                                placeholder="https://facebook.com/my-academy"
                                formControlName="franchise_facebook"
                                [class.is-invalid]="errors?.franchise_facebook?.status === 'INVALID'"
                        />
                        <div class="invalid-feedback" *ngIf="errors?.franchise_facebook?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_facebook?.errors?.minlength && !errors?.franchise_facebook?.errors?.required">{{ 'Полето не може да е по-кратко от 2 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_facebook?.errors?.maxlength">{{ 'Полето надвишава 255 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_facebook?.errors?.any">{{ errors?.franchise_facebook?.errors?.any }}</div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-12">
                        <label for="franchise_location_url">{{ 'Google Maps локация:' | transloco }}</label>
                        <textarea  requiredField requiredFieldAttribute="franchise_location_url"
                                    type="text"
                                    id="franchise_location_url"
                                    class="form-control my-2"
                                    name="franchise_location_url"
                                    rows="5"
                                    [placeholder]="'<iframe ...>...</iframe>'"
                                    formControlName="franchise_location_url"
                                    [readonly]="franchise?.primary"
                        ></textarea>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_location_url?.errors?.maxlength">{{ 'Не може да е повече от 65535 символа' | transloco }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-3 mb-lg-5" *ngIf="mode === 'edit'">
            <div class="card-header">
                <h3 class="my-0 fw-bold">{{ 'Портал – общи данни и SEO' | transloco }}</h3>
            </div>
            <div class="card-body">
                <div class="row">

                    <div class="col mb-3">
                        <label for="franchise_description">{{ 'Meta Описание (SEO):' | transloco }}</label>
                        <textarea  requiredField requiredFieldAttribute="franchise_description"
                                    type="text"
                                    id="franchise_description"
                                    class="form-control my-2"
                                    name="franchise_description"
                                    rows="5"
                                    [placeholder]=""
                                    formControlName="franchise_description"
                        ></textarea>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_description?.errors?.maxlength">{{ 'Не може да е повече от 65535 символа' | transloco }}</div>
                    </div>

                    <div class="col-md-auto mb-3">
                        <div class="row"><label class="col-12 mb-2" for="image_share">{{ 'Снимка за споделяне:' | transloco }}</label></div>
                        <input type="hidden" requiredField requiredFieldAttribute="image_share" formControlName="image_share" />

                        <upload-image (onFinished)="onFileFranchiseFinished($event, 'image_share')"
                                        (onRemove)="imageShare = {};"
                                        [withRemove]="true"
                                        [labelDefault]="'Снимка, 1200x630px' | transloco"
                                        [loading]="loading"
                                        [class.is-invalid]="errors?.image_share?.status === 'INVALID'"
                                        [preview]="imageShare?.url ?? ''"
                        ></upload-image>

                        <div class="invalid-feedback" *ngIf="errors?.image_share?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.image_share?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.image_share?.errors?.any">{{ errors?.meta_image_share?.errors?.any }}</div>
                    </div>
                </div>
            </div>


            <div class="card-header d-flex justify-content-between align-items-center">
                <h3 class="my-0 fw-bold">{{'Начална страница' | transloco}}</h3>
            </div>

            <div class="card-body">

                <div class="row">
                    <div class="col">
                        <div class="row mb-3">
                            <div class="col">
                                <label for="franchise_homepage">{{ 'Линк:' | transloco }}</label>
                                <input type="text"
                                        requiredField requiredFieldAttribute="franchise_homepage"
                                        id="franchise_homepage"
                                        class="form-control my-2"
                                        name="franchise_homepage"
                                        [placeholder]="'https://www.academy.com'"
                                        formControlName="franchise_homepage"
                                        [class.is-invalid]="errors?.franchise_homepage?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.franchise_homepage?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.franchise_homepage?.errors?.minlength && !errors?.franchise_homepage?.errors?.required">{{ 'Полето не може да е по-кратко от 2 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.franchise_homepage?.errors?.maxlength">{{ 'Полето надвишава 255 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.franchise_homepage?.errors?.any">{{ errors?.franchise_homepage?.errors?.any }}</div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-12 mt-3">
                                <label for="franchise_homepage_description">{{ 'Хедър - съдържание в страницата' | transloco }}:</label>

                                <input-inline-text
                                                    requiredField requiredFieldAttribute="franchise_homepage_description"
                                                    formControlName="franchise_homepage_description"
                                                    class="d-block is-invalid"
                                                    [plainText]="false"
                                                    [placeholder]="'Напишете работно време или друга информация' | transloco"
                                                    [class.is-invalid]="errors?.franchise_homepage_description?.status === 'INVALID'"
                                ></input-inline-text>

                                <div class="invalid-feedback" *ngIf="errors?.franchise_homepage_description?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.franchise_homepage_description?.errors?.minlength && !errors?.franchise_homepage_description?.errors?.required">{{ 'Полето не може да е по-кратко от 2 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.franchise_homepage_description?.errors?.maxlength">{{ 'Полето надвишава 255 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.franchise_homepage_description?.errors?.any">{{ errors?.franchise_homepage_description?.errors?.any }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto mb-3">
                        <div class="row"><label class="col-12 mb-2" for="franchise_homepage_background">{{ 'Хедър - фоново изображение:' | transloco }}</label></div>
                        <input type="hidden" requiredField requiredFieldAttribute="franchise_homepage_background" formControlName="franchise_homepage_background" />

                        <upload-image (onFinished)="onFileFranchiseFinished($event, 'homepage_background')"
                                        (onRemove)="homepageBackground = {};"
                                        [withRemove]="true"
                                        [labelDefault]="'Снимка' | transloco"
                                        [loading]="loading"
                                        [class.is-invalid]="errors?.franchise_homepage_background?.status === 'INVALID'"
                                        [preview]="homepageBackground?.url ?? ''"
                        ></upload-image>

                        <div class="invalid-feedback" *ngIf="errors?.franchise_homepage_background?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_homepage_background?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise_homepage_background?.errors?.any">{{ errors?.meta_franchise_homepage_background?.errors?.any }}</div>
                    </div>
                </div>
            </div>

            <div class="card-header d-flex justify-content-between align-items-center">
                <h3 class="my-0 fw-bold">{{'Футър' | transloco}}</h3>
            </div>

            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-12 mt-3">
                        <label for="landing_footer_info">{{ 'Информация във футър (напр. работно време)' | transloco }}:</label>

                        <input-inline-text
                                        requiredField requiredFieldAttribute="landing_footer_info"
                                        formControlName="landing_footer_info"
                                        class="d-block is-invalid"
                                        [plainText]="false"
                                        [placeholder]="'Напишете работно време или друга информация' | transloco"
                                        [class.is-invalid]="form.controls['landing_footer_info'].invalid && form.controls['landing_footer_info'].touched"
                        ></input-inline-text>

                        <div class="invalid-feedback" *ngIf="errors?.landing_footer_info?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.landing_footer_info?.errors?.minlength && !errors?.landing_footer_info?.errors?.required">{{ 'Полето не може да е по-кратко от 2 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.landing_footer_info?.errors?.maxlength">{{ 'Полето надвишава 255 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.landing_footer_info?.errors?.any">{{ errors?.landing_footer_info?.errors?.any }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-3 mb-lg-5">
            <div class="card-header">
                <h3 class="my-0 fw-bold">{{ 'Фирмени данни' | transloco }}</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-6 mb-3">
                        <label for="company_full_name">{{ 'Фирма:' | transloco }}</label>
                        <input  requiredField requiredFieldAttribute="company_full_name"
                                type="text"
                                id="company_full_name"
                                class="form-control my-2"
                                name="company_full_name"
                                [placeholder]="'напр. Advance Academy' | transloco"
                                formControlName="company_full_name"
                                [class.is-invalid]="errors?.company_full_name?.status === 'INVALID'"
                        />
                        <div class="invalid-feedback" *ngIf="errors?.company_full_name?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.company_full_name?.errors?.minlength && !errors?.company_full_name?.errors?.required">{{ 'Полето не може да е по-кратко от 2 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.company_full_name?.errors?.maxlength">{{ 'Полето надвишава 255 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.company_full_name?.errors?.any">{{ errors?.company_full_name?.errors?.any }}</div>
                    </div>

                    <div class="col-lg-6 mb-3">
                        <label for="company_address">{{ 'Адрес:' | transloco }}</label>
                        <input  requiredField requiredFieldAttribute="company_address"
                                type="text"
                                id="company_address"
                                class="form-control my-2"
                                name="company_address"
                                [placeholder]="'Улица, номер, етаж..' | transloco"
                                formControlName="company_address"
                                [class.is-invalid]="errors?.company_address?.status === 'INVALID'"
                        />
                        <div class="invalid-feedback" *ngIf="errors?.company_address?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.company_address?.errors?.minlength && !errors?.company_address?.errors?.required">{{ 'Полето не може да е по-кратко от 2 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.company_address?.errors?.maxlength">{{ 'Полето надвишава 255 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.company_address?.errors?.any">{{ errors?.company_address?.errors?.any }}</div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-lg-6 mb-3">
                        <label for="company_ceo">{{ 'МОЛ:' | transloco }}</label>
                        <input  requiredField requiredFieldAttribute="company_ceo"
                                type="text"
                                id="company_ceo"
                                class="form-control my-2"
                                name="company_ceo"
                                [placeholder]="'напр. Иван Иванов Иванов' | transloco"
                                formControlName="company_ceo"
                                [class.is-invalid]="errors?.company_ceo?.status === 'INVALID'"
                        />
                        <div class="invalid-feedback" *ngIf="errors?.company_ceo?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.company_ceo?.errors?.minlength && !errors?.company_ceo?.errors?.required">{{ 'Полето не може да е по-кратко от 2 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.company_ceo?.errors?.maxlength">{{ 'Полето надвишава 255 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.company_ceo?.errors?.any">{{ errors?.company_ceo?.errors?.any }}</div>
                    </div>

                    <div class="col-lg-6 mb-3">
                        <div class="row">
                            <div class="col">
                                <label for="company_tax_number">{{ 'ЕИК:' | transloco }}</label>
                            </div>
                            <div class="col-auto">
                                <div class="form-check form-switch d-inline-block" [class.is-invalid]="errors?.company_tax_registration?.status === 'INVALID'">
                                    <input  class="form-check-input"
                                            type="checkbox"
                                            id="company_tax_registration"
                                            formControlName="company_tax_registration" />
                                    <label class="form-check-label" for="company_tax_registration">
                                        {{ form.controls['company_tax_registration'].value ? ('Регистрирана по ДДС'|transloco) : ('Не е регистрирана по ДДС'|transloco) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="position-relative " [class.is-invalid]="errors?.company_tax_number?.status === 'INVALID'">
                            <input requiredField requiredFieldAttribute="company_tax_number"
                                    type="text"
                                    id="company_tax_number"
                                    class="form-control my-2 pe-5"
                                    name="company_tax_number"
                                    [placeholder]="'ЕИК / ДДС номер' | transloco"
                                    formControlName="company_tax_number"
                                    [class.is-invalid]="errors?.company_tax_number?.status === 'INVALID'"
                            />
                        </div>

                        <div class="invalid-feedback" *ngIf="errors?.company_tax_number?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.company_tax_number?.errors?.pattern">{{ 'Невалидни символи' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.company_tax_number?.errors?.maxlength">{{ 'Полето надвишава 10 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.company_tax_number?.errors?.any">{{ errors?.company_tax_number?.errors?.any }}</div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12 mb-3">
                        <div class="row">
                            <div class="col">
                                <label for="company_vtc_name">{{ 'ЦПО наименование (лиценз):' | transloco }}</label>
                            </div>
                            <div class="col-auto">
                                <a href="https://www.navet.government.bg/bg/registar-na-tsentrovete-za-profesiona/" target="_blank">{{ 'ЦПО регистър' | transloco }}</a>
                            </div>
                        </div>

                        <input  requiredField requiredFieldAttribute="company_vtc_name"
                                type="text"
                                id="company_vtc_name"
                                class="form-control"
                                [placeholder]="'напр. ЦПО към „Моята компания“ ООД' | transloco "
                                formControlName="company_vtc_name"
                                [attr.disabled]="loading ? true : null"
                                [class.is-invalid]="errors?.preferences?.controls?.company_vtc_name?.status === 'INVALID'"
                        />
                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.company_vtc_name?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.company_vtc_name?.errors?.any">{{ errors?.preferences?.controls?.company_vtc_name?.errors?.any }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5" *ngIf="!franchise?.primary">
            <div class="col-12">
                <div class="payment-tabs" id="payment-tabs">
                    <tabs paramType="id" paramName="payment" [fallbackToAccordion]="false" [fullWidth]="true">

                        <!-- Office Tab -->
                        <tab [label]="'Офис' | transloco" id="TabOffice">
                            <div class="col mx-3 mt-4">
                                <div class="my-3 row">
                                    <div class="col-12 d-flex justify-content-start mb-2">
                                        <div class="form-check form-switch d-inline-block" [class.is-invalid]="errors?.status?.status === 'INVALID'">
                                            <input class="form-check-input"
                                                   type="checkbox"
                                                   id="payments_office_status"
                                                   formControlName="payments_office_status"
                                                   [checked]="form.controls['payments_office_status'].value" />
                                            <label class="form-check-label" for="payments_office_status">{{ form.controls['payments_office_status'].value ? ('С плащане в офис'|transloco) : ('Без плащане в офис'|transloco) }}</label>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <label for="payments_office_description">{{ 'Описание:' | transloco }}</label>
                                        <input-text-editor
                                               formControlName="payments_office_description"
                                               [class.is-invalid]="form.controls['payments_office_description'].invalid && form.controls['payments_office_description'].touched"
                                        ></input-text-editor>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_office_description?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_office_description?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                    </div>
                                </div>
                            </div>
                        </tab>

                        <!-- Bank Tab -->
                        <tab [label]="'Банка' | transloco" id="TabBank">
                            <div class="col mx-3 mt-4">
                                <div class="my-3 row">
                                    <div class="col-12 d-flex justify-content-start mb-2">
                                        <div class="form-check form-switch d-inline-block" [class.is-invalid]="errors?.status?.status === 'INVALID'">
                                            <input class="form-check-input"
                                                   type="checkbox"
                                                   id="payments_bank_status"
                                                   formControlName="payments_bank_status"
                                                   [checked]="form.controls['payments_bank_status'].value" />
                                            <label class="form-check-label" for="payments_bank_status">{{ form.controls['payments_bank_status'].value ? ('С плащане по банков път'|transloco) : ('Без плащане по банков път'|transloco) }}</label>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <label for="payments_bank_description">{{ 'Описание:' | transloco }}</label>
                                        <input-text-editor
                                               formControlName="payments_bank_description"
                                               [class.is-invalid]="form.controls['payments_bank_description'].invalid && form.controls['payments_bank_description'].touched"
                                        ></input-text-editor>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_bank_description?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_bank_description?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                    </div>
                                </div>
                            </div>
                        </tab>

                        <!-- PayPal Tab -->
                        <tab [label]="'PayPal' | transloco" id="TabPaypal">
                            <div class="col mx-3 mt-4">
                                <div class="my-3 row">
                                    <div class="col-12">
                                        <div class="form-check form-switch d-inline-block" [class.is-invalid]="errors?.payments_online_paypal_status?.status === 'INVALID'">
                                            <input class="form-check-input"
                                                   type="checkbox"
                                                   id="payments_online_paypal_status"
                                                   formControlName="payments_online_paypal_status"
                                                   [checked]="form.controls['payments_online_paypal_status'].value" />
                                            <label class="form-check-label" for="payments_online_paypal_status">{{ form.controls['payments_online_paypal_status'].value ? ('С плащане чрез PayPal'|transloco) : ('Без плащане чрез PayPal'|transloco) }}</label>
                                        </div>
                                    </div>

                                    <div class="col-12 mt-3">
                                        <div class="form-check form-switch d-inline-block" [class.is-invalid]="errors?.payments_online_paypal_mode?.status === 'INVALID'">
                                            <input class="form-check-input"
                                                   type="checkbox"
                                                   id="payments_online_paypal_mode"
                                                   formControlName="payments_online_paypal_mode"
                                                   [checked]="form.controls['payments_online_paypal_mode'].value" />
                                            <label class="form-check-label" for="payments_online_paypal_mode">{{ form.controls['payments_online_paypal_mode'].value ? ('Live (Реален режим)'|transloco) : ('Sandbox (Тестов режим)'|transloco) }}</label>
                                        </div>
                                    </div>

                                    <h2 class="mt-5 mb-0">{{'Sandbox (Тестов режим)' | transloco}}</h2>

                                    <div class="col-md-6 mt-3">
                                        <label for="payments_online_paypal_sandbox_client_id">Client ID:</label>
                                        <input requiredField requiredFieldAttribute="payments_online_paypal_sandbox_client_id"
                                               id="payments_online_paypal_sandbox_client_id"
                                               type="text"
                                               formControlName="payments_online_paypal_sandbox_client_id"
                                               class="form-control my-2"
                                               [class.is-invalid]="form.controls['payments_online_paypal_sandbox_client_id'].invalid && form.controls['payments_online_paypal_sandbox_client_id'].touched" />
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_paypal_sandbox_client_id?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_paypal_sandbox_client_id?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                    </div>

                                    <div class="col-md-6 mt-3">
                                        <label for="payments_online_paypal_sandbox_secret">Client Secret:</label>
                                        <input requiredField requiredFieldAttribute="payments_online_paypal_sandbox_secret"
                                               id="payments_online_paypal_sandbox_secret"
                                               type="text"
                                               formControlName="payments_online_paypal_sandbox_secret"
                                               class="form-control my-2"
                                               [class.is-invalid]="form.controls['payments_online_paypal_sandbox_secret'].invalid && form.controls['payments_online_paypal_sandbox_secret'].touched" />
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_paypal_sandbox_secret?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_paypal_sandbox_secret?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                    </div>

                                    <hr class="mt-4">
                                    <h2 class="m-0">{{'Live (Реален режим)' | transloco}}</h2>

                                    <div class="col-md-6 mt-3">
                                        <label for="payments_online_paypal_live_client_id">Client ID:</label>
                                        <input requiredField requiredFieldAttribute="payments_online_paypal_live_client_id"
                                               id="payments_online_paypal_live_client_id"
                                               type="text"
                                               formControlName="payments_online_paypal_live_client_id"
                                               class="form-control my-2"
                                               [class.is-invalid]="form.controls['payments_online_paypal_live_client_id'].invalid && form.controls['payments_online_paypal_live_client_id'].touched" />
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_paypal_live_client_id?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_paypal_live_client_id?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                    </div>

                                    <div class="col-md-6 mt-3">
                                        <label for="payments_online_paypal_live_secret">Client Secret:</label>
                                        <input requiredField requiredFieldAttribute="payments_online_paypal_live_secret"
                                               id="payments_online_paypal_live_secret"
                                               type="text"
                                               formControlName="payments_online_paypal_live_secret"
                                               class="form-control my-2"
                                               [class.is-invalid]="form.controls['payments_online_paypal_live_secret'].invalid && form.controls['payments_online_paypal_live_secret'].touched" />
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_paypal_live_secret?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_paypal_live_secret?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                    </div>
                                </div>
                            </div>
                        </tab>

                        <!-- MyPOS Tab -->
                        <tab [label]="'MyPOS' | transloco" id="TabMyPOS">
                            <div class="col mx-3 mt-4">
                                <div class="my-3 row">
                                    <div class="col-12">
                                        <div class="form-check form-switch d-inline-block" [class.is-invalid]="errors?.payments_online_mypos_status?.status === 'INVALID'">
                                            <input class="form-check-input"
                                                   type="checkbox"
                                                   id="payments_online_mypos_status"
                                                   formControlName="payments_online_mypos_status"
                                                   [checked]="form.controls['payments_online_mypos_status'].value" />
                                            <label class="form-check-label" for="payments_online_mypos_status">{{ form.controls['payments_online_mypos_status'].value ? ('С плащане чрез MyPOS'|transloco) : ('Без плащане чрез MyPOS'|transloco) }}</label>
                                        </div>
                                    </div>

                                    <div class="col-12 mt-3">
                                        <div class="form-check form-switch d-inline-block" [class.is-invalid]="errors?.payments_online_mypos_type?.status === 'INVALID'">
                                            <input class="form-check-input"
                                                   type="checkbox"
                                                   id="payments_online_mypos_type"
                                                   formControlName="payments_online_mypos_type"
                                                   [checked]="form.controls['payments_online_mypos_type'].value" />
                                            <label class="form-check-label" for="payments_online_mypos_type">{{ form.controls['payments_online_mypos_type'].value ? ('Live (Реален режим)'|transloco) : ('Sandbox (Тестов режим)'|transloco) }}</label>
                                        </div>
                                    </div>

                                    <h2 class="mt-4 mb-0">{{'Sandbox (Тестов режим)' | transloco}}</h2>

                                    <div class="col-lg-6 col-12 mt-3">
                                        <label for="payments_online_mypos_sandbox_sid">SID:</label>
                                        <input requiredField requiredFieldAttribute="payments_online_mypos_sandbox_sid"
                                               id="payments_online_mypos_sandbox_sid"
                                               type="text"
                                               formControlName="payments_online_mypos_sandbox_sid"
                                               class="form-control my-2"
                                               [class.is-invalid]="form.controls['payments_online_mypos_sandbox_sid'].invalid && form.controls['payments_online_mypos_sandbox_sid'].touched" />
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_sandbox_sid?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_sandbox_sid?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                    </div>

                                    <div class="col-lg-6 col-12 mt-3">
                                        <label for="payments_online_mypos_sandbox_wallet">Wallet:</label>
                                        <input requiredField requiredFieldAttribute="payments_online_mypos_sandbox_wallet"
                                               id="payments_online_mypos_sandbox_wallet"
                                               type="text"
                                               formControlName="payments_online_mypos_sandbox_wallet"
                                               class="form-control my-2"
                                               [class.is-invalid]="form.controls['payments_online_mypos_sandbox_wallet'].invalid && form.controls['payments_online_mypos_sandbox_wallet'].touched" />
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_sandbox_wallet?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_sandbox_wallet?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                    </div>

                                    <div class="col-12 mt-3">
                                        <label for="payments_online_mypos_sandbox_key">Key:</label>
                                        <textarea
                                                    requiredField requiredFieldAttribute="payments_online_mypos_sandbox_key"
                                                    id="payments_online_mypos_sandbox_key"
                                                    formControlName="payments_online_mypos_sandbox_key"
                                                    rows="4"
                                                    class="d-block is-invalid form-control"
                                                    [class.is-invalid]="form.controls['payments_online_mypos_sandbox_key'].invalid && form.controls['payments_online_mypos_sandbox_key'].touched"
                                        ></textarea>

                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_sandbox_key?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_sandbox_key?.errors?.maxlength">{{ 'Не може да е повече от 65535 символа' | transloco }}</div>
                                    </div>

                                    <div class="col-12 mt-3">
                                        <label for="payments_online_mypos_sandbox_certificate">Certificate:</label>
                                        <textarea
                                                    requiredField requiredFieldAttribute="payments_online_mypos_sandbox_certificate"
                                                    id="payments_online_mypos_sandbox_certificate"
                                                    formControlName="payments_online_mypos_sandbox_certificate"
                                                    rows="4"
                                                    class="d-block is-invalid form-control"
                                                    [class.is-invalid]="form.controls['payments_online_mypos_sandbox_certificate'].invalid && form.controls['payments_online_mypos_sandbox_certificate'].touched"
                                        ></textarea>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_sandbox_certificate?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_sandbox_certificate?.errors?.maxlength">{{ 'Не може да е повече от 65535 символа' | transloco }}</div>
                                    </div>

                                    <hr class="mt-4">
                                    <h2 class="m-0">{{'Live (Реален режим)' | transloco}}</h2>

                                    <div class="col-lg-6 col-12 mt-3">
                                        <label for="payments_online_mypos_live_sid">SID:</label>
                                        <input requiredField requiredFieldAttribute="payments_online_mypos_live_sid"
                                               id="payments_online_mypos_live_sid"
                                               type="text"
                                               formControlName="payments_online_mypos_live_sid"
                                               class="form-control my-2"
                                               [class.is-invalid]="form.controls['payments_online_mypos_live_sid'].invalid && form.controls['payments_online_mypos_live_sid'].touched" />
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_live_sid?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_live_sid?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                    </div>

                                    <div class="col-lg-6 col-12 mt-3">
                                        <label for="payments_online_mypos_live_wallet_number">Wallet:</label>
                                        <input requiredField requiredFieldAttribute="payments_online_mypos_live_wallet_number"
                                               id="payments_online_mypos_live_wallet_number"
                                               type="text"
                                               formControlName="payments_online_mypos_live_wallet_number"
                                               class="form-control my-2"
                                               [class.is-invalid]="form.controls['payments_online_mypos_live_wallet_number'].invalid && form.controls['payments_online_mypos_live_wallet_number'].touched" />
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_live_wallet_number?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_live_wallet_number?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                    </div>

                                    <div class="col-12 mt-3">
                                        <label for="payments_online_mypos_live_key">Key:</label>
                                        <textarea   requiredField requiredFieldAttribute="payments_online_mypos_live_key"
                                                    id="payments_online_mypos_live_key"
                                                    formControlName="payments_online_mypos_live_key"
                                                    rows="4"
                                                    class="d-block is-invalid form-control"
                                                    [class.is-invalid]="form.controls['payments_online_mypos_live_key'].invalid && form.controls['payments_online_mypos_live_key'].touched"
                                        ></textarea>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_live_key?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_live_key?.errors?.maxlength">{{ 'Не може да е повече от 65535 символа' | transloco }}</div>
                                    </div>

                                    <div class="col-12 mt-3">
                                        <label for="payments_online_mypos_live_certificate">Certificate:</label>
                                        <textarea
                                                    requiredField requiredFieldAttribute="payments_online_mypos_live_certificate"
                                                    id="payments_online_mypos_live_certificate"
                                                    formControlName="payments_online_mypos_live_certificate"
                                                    rows="4"
                                                    class="d-block is-invalid form-control"
                                                    [class.is-invalid]="form.controls['payments_online_mypos_live_certificate'].invalid && form.controls['payments_online_mypos_live_certificate'].touched"
                                        ></textarea>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_live_certificate?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                        <div class="invalid-feedback" *ngIf="errors?.payments_online_mypos_live_certificate?.errors?.maxlength">{{ 'Не може да е повече от 65535 символа' | transloco }}</div>
                                    </div>

                                </div>
                            </div>
                        </tab>
                    </tabs>
                </div>
            </div>
        </div>

        <div class="card mb-3 mb-lg-5" [class.mt-4]="!franchise?.primary" *ngIf="mode === 'edit'">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3 class="my-0 fw-bold">{{'Оформление на сертификати' | transloco}}</h3>
                <button type="button" class="btn btn-primary btn-sm" (click)="showCertificate = !showCertificate">
                    <i class="fa-solid fa-fw" [class.fa-plus]="!showCertificate" [class.fa-minus]="showCertificate"></i> {{'Стилизация' | transloco}}
                </button>
            </div>
            <div class="card-body" *ngIf="showCertificate">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <div class="row">
                                    <div class="col-12 d-flex align-items-center my-2">
                                        <div class="form-check form-switch d-flex flex-column justify-content-center">
                                            <input class="form-check-input"
                                                    type="checkbox"
                                                    id="certificate_template_multipage"
                                                    formControlName="certificate_template_multipage" />
                                            <label class="form-check-label" for="certificate_template_multipage"></label>
                                        </div>
                                        <label for="certificate_template_multipage" class="me-auto me-md-2 text-md-end">{{ 'Множество страници' | transloco }}</label>

                                    </div>
                                    <div class="col-12 d-flex align-items-center my-2">
                                        <div class="form-check form-switch d-flex flex-column justify-content-center">
                                            <input class="form-check-input"
                                                    type="checkbox"
                                                    id="certificate_course_title"
                                                    formControlName="certificate_course_title" />
                                            <label class="form-check-label" for="type"></label>
                                        </div>
                                        <label for="certificate_course_title" class="me-auto me-md-2 text-md-end">{{ 'Заглавие на курс' | transloco }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <tabs class="compact" paramType="index" paramName="certPage" [fallbackToAccordion]="false">
                                <tab [label]="'Първа страница' | transloco">
                                    <div class="mx-3">
                                        <div class="row">
                                            <div class="col-12 md-4 md-lg-0">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="my-3 ng-star-inserted" >
                                                            <div class="mb-2">
                                                                <label>{{'Темплейт (pdf формат):' | transloco}}</label>
                                                            </div>
                                                            <upload-file (onFinished)="onTemplateFinished($event, 1)"
                                                                            *ngIf="!firstPage"
                                                                            [files]="1"
                                                                            [withRemove]="false"
                                                                            variant="inline"
                                                                            type="template"
                                                            ></upload-file>
                                                            <alert [type]="messageFilesType" [message]="messageFiles" (remove)="messageFiles = ''"></alert>
                                                            <div *ngIf="firstPage" class="d-md-flex d-flex-column w-100">
                                                                <h3 class ="file-name d-flex flex-column flex-sm-row m-md-0 my-3">
                                                                    <a [href]="firstPage?.url" target="_blank">{{firstPage.title ?? ''}}</a>
                                                                </h3>
                                                                <button class="btn btn-outline-primary btn-sm mx-2" type="button" (click)="firstPage = null">
                                                                    {{ 'Премахни' | transloco }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <!-- student layout -->
                                                    <div class="col-12 mb-4">
                                                        <div class="d-flex">
                                                            <h3 class="me-auto">{{'Име на курсист:' | transloco}}</h3>
                                                            <div class="d-flex flex-column">
                                                                <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">X</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_first_page_student_coords_x"
                                                                                type="text"
                                                                                id="certificate_first_page_student_coords_x"
                                                                                class="form-control"
                                                                                [placeholder]="'X (хоризонтала)' | transloco "
                                                                                formControlName="certificate_first_page_student_coords_x"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_first_page_student_coords_x?.status === 'INVALID'"
                                                                        />
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_coords_x?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_coords_x?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_coords_x?.errors?.any">{{ errors?.preferences?.controls?.certificate_first_page_student_coords_x?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">Y</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_first_page_student_coords_y"
                                                                                type="text"
                                                                                id="certificate_first_page_student_coords_y"
                                                                                class="form-control"
                                                                                [placeholder]="'Y (вертикала)' | transloco "
                                                                                formControlName="certificate_first_page_student_coords_y"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_first_page_student_coords_y?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_coords_y?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_coords_y?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_coords_y?.errors?.any">{{ errors?.preferences?.controls?.certificate_first_page_student_coords_y?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">W</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_first_page_student_width"
                                                                                type="text"
                                                                                id="certificate_first_page_student_width"
                                                                                class="form-control"
                                                                                [placeholder]="'широчина' | transloco "
                                                                                formControlName="certificate_first_page_student_width"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_first_page_student_width?.status === 'INVALID'"
                                                                        />
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_width?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_width?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_width?.errors?.any">{{ errors?.preferences?.controls?.certificate_first_page_student_width?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">H</span>

                                                                        <input  requiredField requiredFieldAttribute="certificate_first_page_student_height"
                                                                                type="text"
                                                                                id="certificate_first_page_student_height"
                                                                                class="form-control"
                                                                                [placeholder]="'височина' | transloco "
                                                                                formControlName="certificate_first_page_student_height"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_first_page_student_height?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_height?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_height?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_height?.errors?.any">{{ errors?.preferences?.controls?.certificate_first_page_student_height?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3">
                                                                        <p-dropdown [options]="certificateFontOptions"
                                                                                    appendTo="body"
                                                                                    optionLabel="label"
                                                                                    optionValue="value"
                                                                                    formControlName="certificate_first_page_student_font"
                                                                                    styleClass="w-100">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">
                                                                            <i class="fa-solid fa-text-height fa-fw"></i>
                                                                        </span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_first_page_student_size"
                                                                                type="text"
                                                                                id="certificate_first_page_student_size"
                                                                                class="form-control"
                                                                                [placeholder]="'Размер на шрифт' | transloco "
                                                                                formControlName="certificate_first_page_student_size"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_first_page_student_size?.status === 'INVALID'"
                                                                        />
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_size?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_size?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_student_size?.errors?.any">{{ errors?.preferences?.controls?.certificate_first_page_student_size?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                    </div>
                                                    <!-- date layout -->
                                                    <div class="col-12 mb-4">
                                                        <div class="d-flex">
                                                            <h3 class="me-auto">{{'Дата:' | transloco}}</h3>
                                                            <div class="d-flex flex-column">
                                                                <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">X</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_first_page_date_coords_x"
                                                                                type="text"
                                                                                id="certificate_first_page_date_coords_x"
                                                                                class="form-control"
                                                                                [placeholder]="'X (хоризонтала)' | transloco "
                                                                                formControlName="certificate_first_page_date_coords_x"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_first_page_date_coords_x?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_coords_x?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_coords_x?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_coords_x?.errors?.any">{{ errors?.preferences?.controls?.certificate_first_page_date_coords_x?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">Y</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_first_page_date_coords_y"
                                                                                type="text"
                                                                                id="certificate_first_page_date_coords_y"
                                                                                class="form-control"
                                                                                [placeholder]="'Y (вертикала)' | transloco "
                                                                                formControlName="certificate_first_page_date_coords_y"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_first_page_date_coords_y?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_coords_y?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_coords_y?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_coords_y?.errors?.any">{{ errors?.preferences?.controls?.certificate_first_page_date_coords_y?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">W</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_first_page_date_width"
                                                                                type="text"
                                                                                id="certificate_first_page_date_width"
                                                                                class="form-control"
                                                                                [placeholder]="'широчина' | transloco "
                                                                                formControlName="certificate_first_page_date_width"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_first_page_date_width?.status === 'INVALID'"
                                                                        />
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_width?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_width?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_width?.errors?.any">{{ errors?.preferences?.controls?.certificate_first_page_date_width?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">H</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_first_page_date_height"
                                                                                type="text"
                                                                                id="certificate_first_page_date_height"
                                                                                class="form-control"
                                                                                [placeholder]="'височина' | transloco "
                                                                                formControlName="certificate_first_page_date_height"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_first_page_date_height?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_height?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_height?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_height?.errors?.any">{{ errors?.preferences?.controls?.certificate_first_page_date_height?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3">
                                                                        <p-dropdown [options]="certificateFontOptions"
                                                                                    optionLabel="label"
                                                                                    optionValue="value"
                                                                                    appendTo="body"
                                                                                    formControlName="certificate_first_page_date_font"
                                                                                    styleClass="w-100">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">
                                                                            <i class="fa-solid fa-text-height fa-fw"></i>
                                                                        </span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_first_page_date_size"
                                                                                type="text"
                                                                                id="certificate_first_page_date_size"
                                                                                class="form-control"
                                                                                [placeholder]="'Размер на шрифт' | transloco "
                                                                                formControlName="certificate_first_page_date_size"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_first_page_date_size?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_size?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_size?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_first_page_date_size?.errors?.any">{{ errors?.preferences?.controls?.certificate_first_page_date_size?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tab>
                                <tab [label]="'Втора страница' | transloco">
                                    <div class="mx-3">
                                        <div class="row">
                                            <div class="col-12 md-4 md-lg-0">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="my-3 ng-star-inserted" >
                                                            <div class="mb-2">
                                                                <label>{{'Темплейт (pdf формат):' | transloco}}</label>
                                                            </div>
                                                            <upload-file (onFinished)="onTemplateFinished($event, 2)"
                                                                            *ngIf="!secondPage"
                                                                            [files]="1"
                                                                            [withRemove]="false"
                                                                            variant="inline"
                                                                            type="template"
                                                            ></upload-file>
                                                            <alert [type]="messageFilesType" [message]="messageFiles" (remove)="messageFiles = ''"></alert>
                                                            <div *ngIf="secondPage" class="d-md-flex d-flex-column w-100">
                                                                <h3 class ="file-name d-flex flex-column flex-sm-row m-md-0 my-3">
                                                                    <a [href]="secondPage?.url" target="_blank">{{secondPage.title ?? ''}}</a>
                                                                </h3>
                                                                <button class="btn btn-outline-primary btn-sm mx-2" type="button" (click)="firstPage = null">
                                                                    {{ 'Премахни' | transloco }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <!-- course layout -->
                                                    <div class="col-12 mb-4">
                                                        <div class="d-flex">
                                                            <h3 class="me-auto">{{'Курс:' | transloco}}</h3>
                                                            <div class="d-flex flex-column">
                                                                <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">X</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_course_coords_x"
                                                                                type="text"
                                                                                id="certificate_second_page_course_coords_x"
                                                                                class="form-control"
                                                                                [placeholder]="'X (хоризонтала)' | transloco "
                                                                                formControlName="certificate_second_page_course_coords_x"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_course_coords_x?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_coords_x?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_coords_x?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_coords_x?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_course_coords_x?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">Y</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_course_coords_y"
                                                                                type="text"
                                                                                id="certificate_second_page_course_coords_y"
                                                                                class="form-control"
                                                                                [placeholder]="'Y (вертикала)' | transloco "
                                                                                formControlName="certificate_second_page_course_coords_y"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_course_coords_y?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_coords_y?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_coords_y?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_coords_y?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_course_coords_y?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">W</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_course_width"
                                                                                type="text"
                                                                                id="certificate_second_page_course_width"
                                                                                class="form-control"
                                                                                [placeholder]="'широчина' | transloco "
                                                                                formControlName="certificate_second_page_course_width"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_course_width?.status === 'INVALID'"
                                                                        />
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_width?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_width?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_width?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_course_width?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">H</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_course_height"
                                                                                type="text"
                                                                                id="certificate_second_page_course_height"
                                                                                class="form-control"
                                                                                [placeholder]="'височина' | transloco "
                                                                                formControlName="certificate_second_page_course_height"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_course_height?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_height?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_height?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_height?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_course_height?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3">
                                                                        <p-dropdown [options]="certificateFontOptions"
                                                                                    optionLabel="label"
                                                                                    optionValue="value"
                                                                                    formControlName="certificate_second_page_course_font"
                                                                                    appendTo="body"
                                                                                    styleClass="w-100">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">
                                                                            <i class="fa-solid fa-text-height fa-fw"></i>
                                                                        </span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_course_size"
                                                                                type="text"
                                                                                id="certificate_second_page_course_size"
                                                                                class="form-control"
                                                                                [placeholder]="'Размер на шрифт' | transloco "
                                                                                formControlName="certificate_second_page_course_size"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_course_size?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_size?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_size?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_course_size?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_course_size?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                    </div>
                                                    <!-- certificate number layout -->
                                                    <div class="col-12 mb-4">
                                                        <div class="d-flex">
                                                            <h3 class="me-auto">{{'Номер сертификат:' | transloco}}</h3>
                                                            <div class="d-flex flex-column">
                                                                <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">X</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_cert_coords_x"
                                                                                type="text"
                                                                                id=""
                                                                                class="form-control"
                                                                                [placeholder]="'X (хоризонтала)' | transloco "
                                                                                formControlName="certificate_second_page_cert_coords_x"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_cert_coords_x?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_coords_x?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_coords_x?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_coords_x?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_cert_coords_x?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">Y</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_cert_coords_y"
                                                                                type="text"
                                                                                id=""
                                                                                class="form-control"
                                                                                [placeholder]="'Y (вертикала)' | transloco "
                                                                                formControlName="certificate_second_page_cert_coords_y"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_cert_coords_y?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_coords_y?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_coords_y?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_coords_y?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_cert_coords_y?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">W</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_cert_width"
                                                                                type="text"
                                                                                id="certificate_second_page_cert_width"
                                                                                class="form-control"
                                                                                [placeholder]="'широчина' | transloco "
                                                                                formControlName="certificate_second_page_cert_width"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_cert_width?.status === 'INVALID'"
                                                                        />
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_width?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_width?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_width?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_cert_width?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">H</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_cert_height"
                                                                                type="text"
                                                                                id="certificate_second_page_cert_height"
                                                                                class="form-control"
                                                                                [placeholder]="'височина' | transloco "
                                                                                formControlName="certificate_second_page_cert_height"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_cert_height?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_height?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_height?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_height?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_cert_height?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3">
                                                                        <p-dropdown [options]="certificateFontOptions"
                                                                                    optionLabel="label"
                                                                                    optionValue="value"
                                                                                    formControlName="certificate_second_page_cert_font"
                                                                                    appendTo="body"
                                                                                    styleClass="w-100">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">
                                                                            <i class="fa-solid fa-text-height fa-fw"></i>
                                                                        </span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_cert_size"
                                                                                type="text"
                                                                                id="certificate_second_page_cert_size"
                                                                                class="form-control"
                                                                                [placeholder]="'Размер на шрифт' | transloco "
                                                                                formControlName="certificate_second_page_cert_size"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_cert_size?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_size?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_size?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_cert_size?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_cert_size?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                    </div>
                                                    <!-- held date layout  -->
                                                    <div class="col-12 mb-4">
                                                        <div class="d-flex">
                                                            <h3 class="me-auto">{{'Провеждане:' | transloco}}</h3>
                                                            <div class="d-flex flex-column">
                                                                <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">X</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_held_date_coords_x"
                                                                                type="text"
                                                                                id=""
                                                                                class="form-control"
                                                                                [placeholder]="'X (хоризонтала)' | transloco "
                                                                                formControlName="certificate_second_page_held_date_coords_x"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_held_date_coords_x?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_coords_x?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_coords_x?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_coords_x?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_held_date_coords_x?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">Y</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_held_date_coords_y"
                                                                                type="text"
                                                                                id=""
                                                                                class="form-control"
                                                                                [placeholder]="'Y (вертикала)' | transloco "
                                                                                formControlName="certificate_second_page_held_date_coords_y"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_held_date_coords_y?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_coords_y?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_coords_y?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_coords_y?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_held_date_coords_y?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">W</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_held_date_width"
                                                                                type="text"
                                                                                id="certificate_second_page_held_date_width"
                                                                                class="form-control"
                                                                                [placeholder]="'широчина' | transloco "
                                                                                formControlName="certificate_second_page_held_date_width"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_held_date_width?.status === 'INVALID'"
                                                                        />
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_width?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_width?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_width?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_held_date_width?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">H</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_held_date_height"
                                                                                type="text"
                                                                                id="certificate_second_page_held_date_height"
                                                                                class="form-control"
                                                                                [placeholder]="'височина' | transloco "
                                                                                formControlName="certificate_second_page_held_date_height"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_held_date_height?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_height?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_height?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_height?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_held_date_height?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3">
                                                                        <p-dropdown [options]="certificateFontOptions"
                                                                                    optionLabel="label"
                                                                                    optionValue="value"
                                                                                    formControlName="certificate_second_page_held_date_font"
                                                                                    appendTo="body"
                                                                                    styleClass="w-100">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">
                                                                            <i class="fa-solid fa-text-height fa-fw"></i>
                                                                        </span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_held_date_size"
                                                                                type="text"
                                                                                id="certificate_second_page_held_date_size"
                                                                                class="form-control"
                                                                                [placeholder]="'Размер на шрифт' | transloco "
                                                                                formControlName="certificate_second_page_held_date_size"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_held_date_size?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_size?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_size?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_held_date_size?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_held_date_size?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                    </div>
                                                    <!-- studnet layout -->
                                                    <div class="col-12 md-4">
                                                        <div class="d-flex">
                                                            <h3 class="me-auto">{{'Курсист:' | transloco}}</h3>
                                                            <div class="d-flex flex-column">
                                                                <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">X</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_student_coords_x"
                                                                                type="text"
                                                                                id=""
                                                                                class="form-control"
                                                                                [placeholder]="'X (хоризонтала)' | transloco "
                                                                                formControlName="certificate_second_page_student_coords_x"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_student_coords_x?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_coords_x?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_coords_x?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_coords_x?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_student_coords_x?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">Y</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_student_coords_y"
                                                                                type="text"
                                                                                id=""
                                                                                class="form-control"
                                                                                [placeholder]="'Y (вертикала)' | transloco "
                                                                                formControlName="certificate_second_page_student_coords_y"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_student_coords_y?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_coords_y?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_coords_y?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_coords_y?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_student_coords_y?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">W</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_student_width"
                                                                                type="text"
                                                                                id="certificate_second_page_student_width"
                                                                                class="form-control"
                                                                                [placeholder]="'широчина' | transloco "
                                                                                formControlName="certificate_second_page_student_width"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_student_width?.status === 'INVALID'"
                                                                        />
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_width?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_width?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_width?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_student_width?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">H</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_student_height"
                                                                                type="text"
                                                                                id="certificate_second_page_student_height"
                                                                                class="form-control"
                                                                                [placeholder]="'височина' | transloco "
                                                                                formControlName="certificate_second_page_student_height"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_student_height?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_height?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_height?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_height?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_student_height?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3">
                                                                        <p-dropdown [options]="certificateFontOptions"
                                                                                    optionLabel="label"
                                                                                    optionValue="value"
                                                                                    formControlName="certificate_second_page_student_font"
                                                                                    appendTo="body"
                                                                                    styleClass="w-100">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">
                                                                            <i class="fa-solid fa-text-height fa-fw"></i>
                                                                        </span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_student_size"
                                                                                type="text"
                                                                                id="certificate_second_page_student_size"
                                                                                class="form-control"
                                                                                [placeholder]="'Размер на шрифт' | transloco "
                                                                                formControlName="certificate_second_page_student_size"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_student_size?.status === 'INVALID'"
                                                                        />
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_size?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_size?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_student_size?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_student_size?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                    </div>
                                                    <!-- topics layout -->
                                                    <div class="col-12 md-4">
                                                        <div class="d-flex">
                                                            <h3 class="me-auto">{{'Обхванати теми:' | transloco}}</h3>
                                                            <div class="d-flex flex-column">
                                                                <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">X</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_topics_coords_x"
                                                                                type="text"
                                                                                id=""
                                                                                class="form-control"
                                                                                [placeholder]="'X (хоризонтала)' | transloco "
                                                                                formControlName="certificate_second_page_topics_coords_x"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_topics_coords_x?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_coords_x?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_coords_x?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_coords_x?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_topics_coords_x?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">Y</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_topics_coords_y"
                                                                                type="text"
                                                                                id=""
                                                                                class="form-control"
                                                                                [placeholder]="'Y (вертикала)' | transloco "
                                                                                formControlName="certificate_second_page_topics_coords_y"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_topics_coords_y?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_coords_y?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_coords_y?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_coords_y?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_topics_coords_y?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">W</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_topics_width"
                                                                                type="text"
                                                                                id="certificate_second_page_topics_width"
                                                                                class="form-control"
                                                                                [placeholder]="'широчина' | transloco "
                                                                                formControlName="certificate_second_page_topics_width"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_topics_width?.status === 'INVALID'"
                                                                        />
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_width?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_width?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_width?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_topics_width?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">H</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_topics_height"
                                                                                type="text"
                                                                                id="certificate_second_page_topics_height"
                                                                                class="form-control"
                                                                                [placeholder]="'височина' | transloco "
                                                                                formControlName="certificate_second_page_topics_height"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_topics_height?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_height?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_height?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_height?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_topics_height?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3">
                                                                        <p-dropdown [options]="certificateFontOptions"
                                                                                    optionLabel="label"
                                                                                    optionValue="value"
                                                                                    formControlName="certificate_second_page_topics_font"
                                                                                    appendTo="body"
                                                                                    styleClass="w-100">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">
                                                                            <i class="fa-solid fa-text-height fa-fw"></i>
                                                                        </span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_topics_size"
                                                                                type="text"
                                                                                id="certificate_second_page_topics_size"
                                                                                class="form-control"
                                                                                [placeholder]="'Размер на шрифт' | transloco "
                                                                                formControlName="certificate_second_page_topics_size"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_topics_size?.status === 'INVALID'"
                                                                        />
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_size?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_size?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_topics_size?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_topics_size?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                    </div>
                                                    <!-- date layout -->
                                                    <div class="col-12 md-4">
                                                        <div class="d-flex">
                                                            <h3 class="me-auto">{{'Дата:' | transloco}}</h3>
                                                            <div class="d-flex flex-column">
                                                                <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">X</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_date_coords_x"
                                                                                type="text"
                                                                                id=""
                                                                                class="form-control"
                                                                                [placeholder]="'X (хоризонтала)' | transloco "
                                                                                formControlName="certificate_second_page_date_coords_x"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_date_coords_x?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_coords_x?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_coords_x?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_coords_x?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_date_coords_x?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">Y</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_date_coords_y"
                                                                                type="text"
                                                                                id=""
                                                                                class="form-control"
                                                                                [placeholder]="'Y (вертикала)' | transloco "
                                                                                formControlName="certificate_second_page_date_coords_y"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_date_coords_y?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_coords_y?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_coords_y?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_coords_y?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_date_coords_y?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">W</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_date_width"
                                                                                type="text"
                                                                                id="certificate_second_page_date_width"
                                                                                class="form-control"
                                                                                [placeholder]="'широчина' | transloco "
                                                                                formControlName="certificate_second_page_date_width"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_date_width?.status === 'INVALID'"
                                                                        />
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_width?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_width?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_width?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_date_width?.errors?.any }}</div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">H</span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_date_height"
                                                                                type="text"
                                                                                id="certificate_second_page_date_height"
                                                                                class="form-control"
                                                                                [placeholder]="'височина' | transloco "
                                                                                formControlName="certificate_second_page_date_height"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_date_height?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_height?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_height?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_height?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_date_height?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                    <div class="col-lg-6 col-12 px-3">
                                                                        <p-dropdown [options]="certificateFontOptions"
                                                                                    optionLabel="label"
                                                                                    optionValue="value"
                                                                                    formControlName="certificate_second_page_date_font"
                                                                                    appendTo="body"
                                                                                    styleClass="w-100">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-6 col-12 px-3 position-relative">
                                                                        <span class="input-group-text justify-content-center">
                                                                            <i class="fa-solid fa-text-height fa-fw"></i>
                                                                        </span>
                                                                        <input  requiredField requiredFieldAttribute="certificate_second_page_date_size"
                                                                                type="text"
                                                                                id="certificate_second_page_date_size"
                                                                                class="form-control"
                                                                                [placeholder]="'Размер на шрифт' | transloco "
                                                                                formControlName="certificate_second_page_date_size"
                                                                                [attr.disabled]="loading ? true : null"
                                                                                [class.is-invalid]="errors?.preferences?.controls?.certificate_second_page_date_size?.status === 'INVALID'"
                                                                        />

                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_size?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_size?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                        <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificate_second_page_date_size?.errors?.any">{{ errors?.preferences?.controls?.certificate_second_page_date_size?.errors?.any }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tab>
                            </tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="submit-panel bg-white py-2 mb-5 text-center">
            <button class="btn btn-primary"
                        [disabled]="loading ? true : false"
                        type="submit"
                >
                    <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                    <i *ngIf="!loading" class="fa-solid fa-check fa-fw"></i>

                    {{ 'Запиши' | transloco }}
            </button>
        </div>
    </form>
