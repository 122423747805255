import { ModuleTest } from "./module-test.model";
import { Homework } from "./homework.model";

export type GroupModuleStatus = ('active'|'inactive');

export class GroupViewModule {
    id?: number;
    number?: number;
    title?: string;
    status?: GroupModuleStatus;
    homework?: Homework;
    test?: ModuleTest;
    can_do_test?: any;
    schedule_date?: string;

    static fromJson(fields: any): GroupViewModule {
        let item = Object.assign(new this(), fields);
        item.test = ModuleTest.fromJson(fields?.test);
        item.homework = Homework.fromJson(fields?.homework);
        return item;
    }

    toggleStatus(): GroupModuleStatus {
        return this.status === 'active' ? 'inactive' : 'active';
    }
}
