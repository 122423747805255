
<div class="mb-2">
    <div class="mt-4 mb-2 mb-lg-4" *ngIf="!hideSearchBar && !hideActionBar">
        <div class="row toolbar w-100">
            <div *ngIf="!hideSearchBar" class="mb-2 mb-sm-0 pe-0" [ngClass]="{
                'col': !isActionsSlotEmpty,
                'col-sm-12 col-md-5': isActionsSlotEmpty,
                'col-lg-8': !searchBarWider,
                'col-xl-8': searchBarWider
            }">
                <div class="input-group search-bar form-control">
                    <input type="text" class="form-control"
                            [placeholder]="'Търсене' | transloco"
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                            [(ngModel)]="search"
                            (keyup.enter)="onSearchEnter($event)"
                            (input)="onSearchDebounce($event)"
                    />

                    <button class="btn btn-outline-danger" type="button" *ngIf="search?.length" (click)="onClearSearch($event)"><i class="fa-solid fa-fw fa-xmark"></i></button>
                    <button class="btn btn-outline-secondary submit" type="button" (click)="onSearchEnter($event)"><i class="fa-solid fa-fw fa-magnifying-glass"></i></button>
                    <div [class]="'dropdown ' + (actionsDropdownWhen?.length ? actionsDropdownWhen : 'd-inline-block d-md-none')">
                        <button class="btn btn-outline-secondary dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-boundary="viewport"
                                aria-haspopup="true"
                                aria-expanded="false"
                                (click)="showActionsMenu = !showActionsMenu"
                                undoClick (onUndoClick)="showActionsMenu = false"
                        >
                            <i class="fa-solid fa-fw fa-bars"></i>
                        </button>

                        <div class="dropdown-menu dropdown-menu-end" [class.show]="showActionsMenu" *ngIf="actions && actions.length">
                            <ng-container *ngFor="let item of actions; let index = index">
                                <div *ngIf="item.separator" class="dropdown-divider"></div>
                                <a *ngIf="!item.separator && item?.path?.length"
                                    [routerLink]="item.path"
                                    [queryParams]="item.queryParams ?? {}"
                                    class="dropdown-item"
                                    [ngClass]="{disabled: item.disabled}"
                                    [attr.disabled]="item.disabled"
                                >
                                    <i class="fa-fw {{ item.icon || '' }}"></i> {{ item.name }}
                                </a>

                                <a *ngIf="!item.separator && !(item.path || '').length && item.action"
                                    href="#"
                                    class="dropdown-item"
                                    [ngClass]="{disabled: item.disabled}"
                                    [attr.disabled]="item.disabled"
                                    (click)="onActionClick($event, item.action)"
                                >
                                    <i class="fa-fw {{ item.icon || '' }}"></i>
                                    {{ item.name }}
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!hideActionBar" class="col-auto pe-0 text-end mb-2 mb-md-0 actions" [ngClass]="{'d-none d-md-block': isActionsSlotEmpty}">
                <ng-content select=".actions"></ng-content>

                <div *ngIf="(actions && actions.length) || withSorting">
                    <div class="d-inline-block d-lg-none" *ngIf="withSorting">

                        <div class="dropdown">
                            <button class="btn btn-outline-secondary dropdown-toggle no-caret"
                                    type="button"
                                    data-bs-boundary="viewport"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                            >
                                <i class="fa-solid fa-sort fa-fw"></i>
                                <span class="d-none d-sm-inline-block"> {{ 'Сортирай' | transloco }}</span>
                            </button>

                            <div class="dropdown-menu dropdown-menu-end">
                                <ng-container *ngFor="let column of columns; let index = index">
                                    <button *ngIf="column.sortField?.length"
                                            class="dropdown-item py-2"
                                            [attr.title]="'Подреждане по \{{columnName}\}' | transloco:{columnName: column.name}"
                                            [class.active]="column.direction?.length"
                                            (click)="onSortBy($event, column)"
                                    >
                                        <i class="fa-solid fa-fw" [ngClass]="{
                                            'fa-arrow-down-short-wide': column.direction === 'asc',
                                            'fa-arrow-down-wide-short': column.direction === 'desc'
                                        }"></i>

                                        {{ 'По \{{sortedColumnName}\}' | transloco:{sortedColumnName: column?.name } }}
                                    </button>
                                </ng-container>

                                <ng-container  *ngIf="sort && (sort | json) != '{}'">
                                    <hr class="dropdown-divider">
                                    <button class="dropdown-item py-2" (click)="onSortRemoveAll($event)">
                                        {{ 'Премахване на всички' | transloco }}
                                    </button>
                                </ng-container>

                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="actions && actions.length">
                        <ng-container *ngFor="let item of actions; let index = index">
                            <ng-container *ngIf="item.separator"> </ng-container>
                            <a
                                *ngIf="!item.separator && (item.path || '').length"
                                class="ms-1 btn {{ item.btnClass || 'btn-primary' }}"
                                [routerLink]="item.path"
                                [queryParams]="item.queryParams ?? {}"
                            >
                                <i class="fa-fw {{ item.icon || '' }}"></i> {{ item.name }}
                            </a>
                            <button *ngIf="!item.separator && !(item.path || '').length && item.action"
                                    class="ms-1 btn {{ item.btnClass || 'btn-primary' }}"
                                    [disabled]="item.disabled"
                                    (click)="onActionClick($event, item.action)"
                            >
                                <i class="fa-fw {{ item.icon || '' }}"></i> {{ item.name }}
                            </button>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>

        <ng-content select=".toolbar"></ng-content>

        <div #sortByItems class="row w-100" *ngIf="sort && (sort | json) != '{}'">
            <div class="col mt-2" cdkDropList (cdkDropListDropped)="drop($event)" >
                <div *ngFor="let item of sort; let index = index"
                    class="btn-group me-1 mb-1"
                    role="group"
                    cdkDrag
                    [cdkDragBoundary]="sortByItems"
                >
                    <button type="button" class="btn btn-sm btn-outline-secondary text-nowrap"
                            [title]="item.direction === 'asc' ? ('Сортирай низходящо (9 &rarr; 1)' | transloco) : ('Сортирай възходящо (1 &rarr; 9)' | transloco)"
                            aria-haspopup="true"
                            aria-expanded="false"
                            (click)="onSortBy($event, item, item.direction === 'asc' ? 'desc' : 'asc')"
                    >
                        <i class="fa-solid fa-fw" [ngClass]="{
                            'fa-arrow-down-short-wide': item.direction === 'asc',
                            'fa-arrow-down-wide-short': item.direction === 'desc'
                        }"></i>

                        {{ item.name }}
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-secondary"
                            [title]="'Премахване' | transloco"
                            aria-haspopup="true"
                            aria-expanded="false"
                            (click)="onSortRemove($event, item)"
                    >
                        <i class="fa-solid fa-xmark fa-fw text-danger"></i>
                        <span class="sr-only">{{ 'Премахване' | transloco }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="table-responsive pt-2"
        [ngClass]="{
            overlay: loading && (rows || []).length,
            'dropdown-shown': dropdownMenuShow
        }"
        (show.bs.dropdown)="onDropdownChange($event, true)"
        (hide.bs.dropdown)="onDropdownChange($event, false)"
    >
        <table class="table table-hover mb-0 table-borderless" >
            <caption>&nbsp;</caption>
            <thead>
                <tr>
                    <th scope="col" *ngIf="sequences" class="non-sortable">{{ sequenceHeadline }}</th>
                    <ng-container *ngFor="let column of columns; let index = index">
                        <th scope="col"
                            class="fw-normal"
                            [ngClass]="{
                                'non-sortable': !column.sortField?.length,
                                'text-lg-start': column.align && column.align === 'left',
                                'text-lg-end': column.align && column.align === 'right',
                                'text-lg-center': column.align && column.align === 'center',
                                'text-lg-justify': column.align && column.align === 'justify',
                                'd-none': column.show == false
                            }"
                        >
                            <span class="d-flex text-nowrap align-center justify-around">
                                <div class="filters-wrapper d-flex align-center justify-end">
                                    <span [class.fw-bold]="column?.filter?.value || column?.direction?.length" *ngIf="!column.sortField?.length">{{ column.name }}</span>

                                    <a *ngIf="column.sortField?.length"
                                        href="#"
                                        [attr.title]="'Сортирай по \{{columnName}\}'|transloco:{columnName: column.name}"
                                        (click)="onSortBy($event, column)"
                                    >
                                        <span [class.fw-bold]="column?.filter?.value || column?.direction?.length" class="me-1">{{ column.name }}</span>

                                        <i class="fa-solid fa-fw fa-arrow-down-short-wide" *ngIf="column?.direction === 'asc'"></i>
                                        <i class="fa-solid fa-fw fa-arrow-down-wide-short" *ngIf="column?.direction === 'desc'"></i>
                                    </a>

                                    <a href *ngIf="column?.filter?.type === 'dates' || column?.filter?.type === 'date'"
                                        (click)="showModalDate = true; modalDateColumn = column; $event.preventDefault()"
                                    ><i class="fas fa-fw fa-filter small" [class.fw-bold]="column?.filter?.value || column?.filter?.value?.length"></i></a>

                                    <p-multiSelect *ngIf="column.filter && column?.filter?.type === 'list'"
                                                    class="filters"
                                                    [(ngModel)]="column.filter.value"
                                                    [options]="column.filter?.list?.options || []"
                                                    optionLabel="title"
                                                    optionValue="id"
                                                    [filter]="column.filter?.list?.withFilter || false"
                                                    [showHeader]="column.filter?.list?.withFilter || false"
                                                    [showToggleAll]="false"
                                                    [selectedItemsLabel]="' '"
                                                    [maxSelectedLabels]="0"
                                                    [dropdownIcon]="'fas fa-fw fa-filter small ' + (column?.filter?.value ? 'fw-bold' : '')"
                                                    defaultLabel=" "
                                                    [selectionLimit]="column?.filter?.list?.multiple ? 0 : 1"
                                                    appendTo="body"
                                                    (onChange)="onFilterBy($event, column)"
                                                    #inputFiltersMulti
                                    ></p-multiSelect>
                                </div>
                            </span>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="!loading || (rows || []).length">
                    <tr *ngFor="let row of rows; let index = index"
                        [class]="highlights[index] || ''"
                        [class.shown]="row.shown || withoutFolding"
                        (click)="toggleRow($event, row)"
                    >
                        <th scope="row" *ngIf="sequences" [attr.data-title]="sequenceHeadline" [class.wrapped]="withWrappedColumn">
                            {{ (current - 1) * perPage + (index + 1) }}
                        </th>
                        <td *ngFor="let column of columns; let index = index" scope="col" [attr.data-title]="column.name"
                            [ngClass]="{
                                'limit-cell-width': column.limitWidth,
                                'text-nowrap text-start buttons': column.buttons,
                                'd-none': column.show == false
                            }"
                            [class]="( column.align && column.align ? 'text-xl-' +  (column?.align === 'left' ? 'start' : column?.align === 'right' ? 'end' : column?.align) : '')"
                            [class.wrapped]="withWrappedColumn"
                            (click)="onCellClick($event, row)"
                        >
                            <ng-container *ngIf="column.buttons">
                                <button [class.d-lg-none]="row[column?.field || '']?.length <= maxNonDropdownActionsCount"
                                        class="btn btn-default btn-sm dropdown-toggle no-caret btn-more"
                                        (click)="(row.shown = true) && toggleOverlay($event, overlayPanelModules) "
                                >
                                    <i class="fa-solid fa-ellipsis-vertical fa-fw d-inline-block"></i>
                                </button>

                                <p-overlayPanel #overlayPanelModules
                                    [dismissable]="true" appendTo="body"
                                    showTransitionOptions="0.01s"
                                    hideTransitionOptions="0.01s"
                                >
                                    <ng-template pTemplate="content">
                                        <ng-container *ngFor="let item of row[column?.field || '']; let index = index; let last = last">

                                            <a
                                                *ngIf="item?.path?.length"
                                                class="custom-dropdown-item"
                                                [routerLink]="item.path"
                                                [queryParams]="item.queryParams ?? {}"
                                            >
                                                <i *ngIf="item.icon" class="fa-fw {{ item.icon || '' }}"></i>
                                                {{ item.name }}
                                            </a>

                                            <button
                                                *ngIf="!item?.path?.length"
                                                (click)="onRowActionClick($event, row, item)"
                                                class="custom-dropdown-item"
                                            >
                                                <i *ngIf="item.icon" class="fa-fw {{ item.icon || '' }}"></i>
                                                {{ item.name }}
                                            </button>

                                            <div class="dropdown-divider" *ngIf="!last"></div>
                                        </ng-container>

                                    </ng-template>
                                </p-overlayPanel>

                                <div class="d-none" [class.d-lg-block]="row[column?.field || '']?.length <= maxNonDropdownActionsCount">
                                    <ng-container *ngFor="let item of row[column?.field || '']; let index = index">
                                        <a
                                            *ngIf="item?.path?.length"
                                            class="btn btn-sm text-nowrap {{ item.btnClass || '' }} me-1 mb-1"
                                            [class.btn-secondary]="!item?.btnClass?.length"
                                            [routerLink]="item.path"
                                            [queryParams]="item.queryParams ?? {}"
                                        >
                                            <i *ngIf="item.icon" class="fa-fw {{ item.icon || '' }}"></i>
                                            {{ item.name }}
                                        </a>

                                        <button
                                            *ngIf="!item?.path?.length"
                                            (click)="onRowActionClick($event, row, item)"
                                            class="btn btn-sm text-nowrap {{ item.btnClass || '' }} me-1 mb-1"
                                            [class.btn-secondary]="!item?.btnClass?.length"
                                        >
                                            <i *ngIf="item.icon" class="fa-fw {{ item.icon || '' }}"></i>
                                            {{ item.name }}
                                        </button>

                                    </ng-container>
                                </div>
                            </ng-container>
                            <span *ngIf="!column.buttons && column?.html && isFunction(column?.html)" [innerHTML]="column?.html(row[column?.field || ''], row)"></span>
                            <ng-container *ngIf="!column.buttons && !column?.html">
                                <ng-container *ngIf="row[column?.field || ''] | isArray">
                                    <span class="badge badge-pill badge-primary me-1 mb-1" *ngFor="let item of row[column?.field || '']">
                                        {{ item }}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="!(row[column?.field || ''] | isArray)">
                                    {{ row[column?.field || ''] || column.defaultValue || defaultCellValue }}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="column.template === true">
                                <ng-container *ngTemplateOutlet="templateCell || null; context: {$implicit: {row: row, column: column, cell: row[column?.field || '']}}"></ng-container>
                            </ng-container>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="noData">
                    <tr class="shown no-data" *ngIf="!loading">
                        <td [attr.colspan]="columns.length + (sequences ? 1 : 0)" class="d-flex d-lg-table-cell text-center align-items-center justify-content-center">
                            {{ 'Няма резултати.' | transloco }}
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot *ngIf="(!loading || (rows || []).length) && !hideTotalBar">
                <tr>
                    <th [attr.colspan]="columns.length + (sequences ? 1 : 0)">
                        <div class="d-flex align-items-lg-center align-items-baseline justify-content-start flex-row">
                            <pagination *ngIf="total > perPage"
                                        [current]="current"
                                        [total]="total"
                                        [perPage]="perPage"
                                        class="mb-4 mb-lg-0"
                                        (page)="onPageChange($event)"
                            ></pagination>
                            <div class="ms-2" [ngTemplateOutlet]="totalRecords"></div>
                        </div>
                    </th>
                </tr>
            </tfoot>
        </table>
        <div *ngIf="loading && !noData" class="loading-wrap text-center">
            <div class="spinner-wrapper">
                <div class="spinner-border" style="width: 3rem; height: 3rem; margin: 1.5rem" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</div>

<modal-confirm *ngIf="confirmShow"
               [body]="confirmMessage"
               [headline]="confirmMessageTitle"
               [skippable]="true"
               (onAction)="onConfirmAction($event)"
></modal-confirm>

<ng-template #totalRecords>
    <div class="text-dark d-flex align-items-center justify-content-start position-relative">
        <ng-container *ngIf="rows?.length">
            <small>
                {{ '\{{startResultNumber}\}-\{{endResultNumber}\} от \{{totalResult}\}' | transloco:{startResultNumber: perPage * (current - 1) + 1, endResultNumber: currentCount, totalResult: total} }}
            </small>
        </ng-container>

        <small *ngIf="!rows?.length">{{ 'Общо:' | transloco }} 0</small>

        <div class="limit-chooser dropup d-inline-block position-absolute">
            <button class="btn btn-default btn-sm dropdown-toggle no-caret p-1 py-0 mx-2"
                    type="button"
                    data-bs-boundary="scrollParent"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
            >
                <i class="fa-solid fa-ellipsis-vertical fa-fw"></i>
            </button>

            <div class="dropdown-menu dropdown-menu-end align-middle">
                <button *ngFor="let item of limits"
                        (click)="onLimitChange($event, item)"
                        class="dropdown-item"
                        [class.active]="item === limit"
                >
                    <small>{{ 'по \{\{ resultsPerRow \}\} реда' | transloco:{resultsPerRow: item} }}</small>
                </button>

                <button (click)="onLimitChange($event, limitPerPage)" class="dropdown-item">
                    <small>{{ 'по подразбиране' | transloco }}</small>
                </button>
            </div>
        </div>
    </div>
</ng-template>
