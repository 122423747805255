import { Course } from "./course.model";
import { Exercise } from "./exercise.model";
import { Group } from "./group.model";
import { Homework } from "./homework.model";
import { Methodology } from "./methodology.model";
import { ModuleTest } from "./module-test.model";
import { Poll } from "./polls/poll.model";
import { Theory } from "./theory.model";

export type ModuleStatus = ('active'|'inactive');

export class Module {
    id?: number;
    number?: string;
    title?: string;
    description?: string;
    skill?: string;
    content?: string;
    status?: ModuleStatus;
    duration?: number;
    course?: Course;
    group?: Group;
    homework?: Homework;
    exercises?: Exercise[];
    methodologies?: Methodology[];
    theory_elements?: Theory[];
    theory_teacher?: string;
    module?: Module;
    test?: ModuleTest;
    polls?: Poll[];
    schedule_date?: string|null;

    static fromJson(fields: any): Module {
        let item = Object.assign(new this(), fields);
        item.course = Course.fromJson(fields?.course);
        item.group = Group.fromJson(fields?.group);
        item.homework = Homework.fromJson(fields?.homework);
        item.test = fields?.test ? ModuleTest.fromJson(fields?.test) : null;
        item.exercises = fields?.exercises?.map((item: any) => Exercise.fromJson(item));
        item.methodologies = fields?.methodologies?.map((item: any) => Methodology.fromJson(item));
        item.theory_elements = fields?.theory_elements?.map((item: any) => Theory.fromJson(item));

        return item;
    }

    toggleStatus(): ModuleStatus {
        return this.status === 'active' ? 'inactive' : 'active';
    }
}
