import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaymentService, PaymentServiceInputMyPos } from '../../services/payment.service';
import { Payment } from '../../models/payment.model';
import { translate } from '@ngneat/transloco';
import { FranchiseService } from '../../services/franchise.service';
import { FranchisePreference } from '../../models/franchise-preference.model';
import { MessageService } from 'primeng/api';
import { Franchise } from '../../models/franchise.model';

export type AllowedPaymentMethods = {
    cash: boolean,
    bank: boolean,
    mypos: boolean,
    paypal: boolean,
}

@Component({
  selector: 'modal-payments-view',
  templateUrl: './modal-payments-view.component.html',
  styleUrls: ['./modal-payments-view.component.scss']
})
export class ModalPaymentsViewComponent implements OnInit {
    @ViewChild('form') formRef: ElementRef|null = null;
    @Output() onAction: EventEmitter<{action: string, paid: boolean}> = new EventEmitter();
    @Input() headline: string = translate('Плащане на курс');

    @Input() closable: boolean = true;
    @Input() amount: number = 0;
    @Input() paymentId: number = 0;
    @Input() title: string|undefined = 'Advance Academy';
    @Input() description: string|undefined = translate('Плащане към Advance Academy.');

    protected subscriptions: Subscription[] = [];
    payPalConfig?: IPayPalConfig;

    showSuccess: boolean = false;
    showCancel: boolean = false;
    showError: boolean = false;
    loading: boolean = false;
    paid: boolean = false;
    paymentForm: string = '';
    payment: Payment = new Payment();
    allowedPaymentMethods: AllowedPaymentMethods = {
        cash: false,
        bank: false,
        mypos: false,
        paypal: false,
    };


    message: string = '';
    messageType: ('error'|'success'|'warning') = 'error';

    franchise: Franchise = new Franchise();

    franchiseOfficeInformation: any = '';
    franchiseBankInformation: any = '';
    paymentMethodsCount: number = 0;
    showPaymentByDefault: ('cash'|'bank'|'online') = 'cash';

    constructor(
        protected paymentService: PaymentService,
        protected franchiseService: FranchiseService,
        protected messageService: MessageService,
    ) { }

    ngOnInit(): void {
        this.getPayment();
    }

    private getPayment():void {
        this.loading = true;
        const subscription = this.paymentService.getItem(this.paymentId).subscribe({
            next: (data: any) => {
                this.payment = data.data;
                this.headline += ' ' + this.payment.group?.course?.title ?? '';

                this.loading = false;
                this.determinePaymentMethods();
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    detail: translate('Грешка при извличане на плащането!')
                })
                this.loading = false;
            }
        });

        this.subscriptions.push(subscription);
    }

    private initPayPal(): void {
        const price: string = this.amount.toString() ?? '1';

        const currentMode = this.franchise.getPreference(FranchisePreference.PREFERENCE_PAYMENTS_PAYPAL_MODE)?.value;
        const clientId = this.franchise.getPreference(
            currentMode === 'sandbox' ?
            FranchisePreference.PREFERENCE_PAYMENTS_PAYPAL_SANDBOX_CLIENT_ID
            : FranchisePreference.PREFERENCE_PAYMENTS_PAYPAL_LIVE_CLIENT_ID
        )?.value?.toString() ?? '';

        this.payPalConfig = {
            currency: 'EUR',
            clientId: this.franchise?.primary ? environment.services.paypal.key : clientId,
            createOrderOnClient: (data) => {
                this.loading = true;
                return {
                    intent: 'CAPTURE',
                    purchase_units: [{
                        description: this.description,
                        custom_id: this.paymentId.toString() ?? '0',
                        amount: {
                            currency_code: 'EUR',
                            value: price,
                            breakdown: {
                                item_total: {
                                    currency_code: 'EUR',
                                    value: price
                                }
                            }
                        },
                        items: [{
                            name: this.title,
                            quantity: '1',
                            category: 'DIGITAL_GOODS',
                            unit_amount: {
                                currency_code: 'EUR',
                                value: price,
                            },
                        }]
                    }]
                } as ICreateOrderRequest;
            },
            advanced: {
                commit: 'true'
            },
            style: {
                layout: 'horizontal',
                tagline: false
            },
            onApprove: async (data, actions) => {
                this.messageType = 'warning';
                this.message = translate('Плащането се обработва...');

                try {
                    await actions.order.get().then((details: any) => {
                        return details.id;
                    });
                } catch (e) {
                    this.onPaymentError(e);
                }
            },
            onClientAuthorization: (order) => {
                this.purchase(order.id);
            },
            onCancel: () => {
                this.loading = false;
            },
            onShippingChange: (data, actions) => {
                return actions.resolve();
            },
            onError: error => this.onPaymentError(error),
        };
    }

    purchase(order: string): void {
        this.paymentService.paypalPurchase(this.paymentId, order).subscribe({
            next: (data: any) => {
                this.messageType = 'success';
                this.message = translate('Плащането беше завършено успешно.');
                this.paid = true;

                setTimeout(() => {
                    this.onCloseAction();
                    this.loading = false;
                }, 3000);
            },
            error: error => this.onPaymentError(error)
        });
    }

    onBuyConfirmed(event: Event): void {
        if (this.loading) {
            return;
        }
        this.loading = true;

        const data: PaymentServiceInputMyPos = this.franchise.primary ? {}
            : {
                cancel_url: this.franchiseService.buildFranchisePageUrl(this.franchise, '/payments/mypos/canceled', 'https:'),
                ok_url: this.franchiseService.buildFranchisePageUrl(this.franchise, '/payments/mypos/ok', 'https:'),
            };

        this.paymentService.myposCreate(this.paymentId, data).subscribe({
            next: (data: any) => {
                this.paymentForm = data.replace(/<\/body>/g, '').replace(/<body[^\>]+>/g, '');
                setTimeout(() => {
                    this.formRef?.nativeElement?.querySelector("form").setAttribute('target', '_blank');
                    this.formRef?.nativeElement?.querySelector("form").submit();

                    this.loading = false;
                }, 100);
            },
            error: error => {
                this.onPaymentError(error);
                this.loading = false;
            }
        })
    }

    onPaymentError(error?: any): void {
        this.messageType = 'error';
        this.message = translate('Възникна грешка при плащането!');
        this.loading = false;
    }

    onCloseAction(e?: any): void {
        this.onAction.emit({action: 'close', paid: this.paid});
    }

    ngOnDestroy() {
        this.subscriptions.map(item => item?.unsubscribe());
    }

    determinePaymentMethods(): void {
        this.loading = true;
        this.franchiseService.getCompanyDetails().subscribe({
            next: (data) => {
                this.loading = false;

                this.franchise = data ?? new Franchise();

                this.franchiseOfficeInformation = this.franchise.getPreference(FranchisePreference.PREFERENCE_PAYMENTS_OFFICE_DESCRIPTION)?.value ?? '';
                this.franchiseBankInformation = this.franchise.getPreference(FranchisePreference.PREFERENCE_PAYMENTS_BANK_DESCRIPTION)?.value ?? '';

                if (this.franchise.getPreference(FranchisePreference.PREFERENCE_PAYMENTS_OFFICE_STATUS)?.value) {
                    this.allowedPaymentMethods.cash = true;
                    this.paymentMethodsCount++;
                    this.paymentMethodsCount === 1 && (this.showPaymentByDefault = 'cash');
                }

                if (this.franchise.getPreference(FranchisePreference.PREFERENCE_PAYMENTS_BANK_STATUS)?.value) {
                    this.allowedPaymentMethods.bank = true;
                    this.paymentMethodsCount++;
                    this.paymentMethodsCount === 1 && (this.showPaymentByDefault = 'bank');
                }

                if (this.franchise.getPreference(FranchisePreference.PREFERENCE_PAYMENTS_MYPOS_STATUS)?.value) {
                    this.allowedPaymentMethods.mypos = true;
                }

                if (this.franchise.getPreference(FranchisePreference.PREFERENCE_PAYMENTS_PAYPAL_STATUS)?.value) {
                    this.allowedPaymentMethods.paypal = true;
                }

                if (this.allowedPaymentMethods.paypal || this.allowedPaymentMethods.mypos) {
                    this.paymentMethodsCount++;
                    this.paymentMethodsCount === 1 && (this.showPaymentByDefault = 'online');
                }

                if (this.allowedPaymentMethods.paypal || this.franchise?.primary) {
                    this.initPayPal();
                }

                if (this.franchise?.primary && !this.allowedPaymentMethods.paypal && !this.allowedPaymentMethods.mypos) {
                    this.paymentMethodsCount++;
                }

                this.paymentMethodsCount = this.paymentMethodsCount > 3 ? 3 :  this.paymentMethodsCount;
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    detail: translate('Грешка при извеждане на партньор!')
                });
                this.loading = false;
            }
        })
    }
}
